import React, { useRef } from 'react'
import './index.less'
import postRobot from 'post-robot'
import { FormInstance } from 'antd'
import config from '@/config'
import { CCMVerifyMode } from '.'
import CodeRunner from './CodeRunner'
import CcmQuizItem from './CcmQuizItem'

type DebugProps = {
    form?: FormInstance
    formData: any
    changeData: (data: any) => void
}

const Debug: React.FC<DebugProps> = (props) => {

    const { formData, changeData } = props

    // const iframeDom = useRef<HTMLIFrameElement>(null)


    // const onLoad = async () => {
    //     try {
    //         const win = iframeDom.current?.contentWindow!
    //         const {
    //             topic: title = '',
    //             title: content = '',
    //             codeLanguage: lang = '',
    //             code = '',
    //             example: cases = [],
    //             testExample: submitCases = [],
    //         } = formData
    //         let response = await postRobot.send(win, 'send', {
    //             title, content, lang, initCode: code, code, cases, submitCases, aiEnabled: true,
    //         })
    //         let resData = { ...formData}
    //         // 代码块编辑回调
    //         postRobot.on("change", { window: iframeDom.current?.contentWindow }, async function (event: any) {
    //             resData.code = event.data ?? '' 
    //             changeData(resData)
    //         })
    //         // 其他字段编辑回调
    //         postRobot.on("saveQuestion", { window: iframeDom.current?.contentWindow }, async function (event: any) {
    //             if (event.data.title != undefined) {
    //                 const { title, content } = event.data
    //                 if (title?.length < 3 || title?.length > 50) {
    //                     return { error: '请输入3-50字的标题' }
    //                 }
    //                 if (content) {
    //                     const dom = (new DOMParser()).parseFromString(content, 'text/html')
    //                     if (!dom.getElementsByTagName('img')?.length && !dom.body.innerText) {
    //                         return { error: '请输入题干内容' }
    //                     }
    //                 } else {
    //                     return { error: '请输入题干内容' }
    //                 }
    //                 resData.topic = title
    //                 resData.title = content
    //             } else if (event.data?.cases != undefined) {
    //                 const { cases, submitCases } = event.data
    //                 if (!cases?.length) {
    //                     return { error: '请输入测试样例' }
    //                 } else if (cases?.length > 10) {
    //                     return { error : '测试样例至多设置10个'}
    //                 } else {
    //                     for (let i = 0; i < cases.length; i++) {
    //                         const item = cases[i]
    //                         if (!item.output) {
    //                             return { error: '测试样例输出不可为空' }
    //                         }
    //                         if (!item.input.every((str: string) => !!str)) {
    //                             return { error: '测试样例输入不可为空' }
    //                         }
    //                     }
    //                 }
    
    //                 if (!submitCases?.length) {
    //                     return { error: '请输入测试用例' }
    //                 } else if (submitCases?.length > 10) {
    //                     return { error : '测试用例至多设置10个'}
    //                 } else {
    //                     for (let i = 0; i < submitCases.length; i++) {
    //                         const item = submitCases[i]
    //                         if (!item.output) {
    //                             return { error: '测试用例输出不可为空' }
    //                         }
    //                         if (!item.input.every((str: string) => !!str)) {
    //                             return { error: '测试用例输入不可为空' }
    //                         }
    //                     }
    //                 }
    //                 resData.example = cases
    //                 resData.testExample = submitCases
    //             }
    //             changeData(resData)
    //         })
    //     } catch (error) {
            
    //     }
    // }

    const change = async (data: any) => {
        changeData(data)
    }

    return (
        <div className='content3'>
            {
                formData?.verifyMode === CCMVerifyMode.INPUT_OUTPUT ? <CcmQuizItem data={formData} changeData={change}/> :
                <CodeRunner data={formData} changeData={change} />
            }
        </div>
    )
}

export default Debug