import React, { useState, useEffect, Fragment, useRef, useCallback, lazy } from 'react'
import { Button, Modal, message } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import Container from '../Container'
import { Markdown } from '@ylzcc/textreader'
import CryptoJS from 'crypto-js'
import SvgIcon from '../SvgIcon'
import TemplateModal from '../TemplateModal'
// import VideoPlayer from '../VideoPlayer'
// import CodeEditor from '../CodeEditor';
// import Webview from '../webview';
import { getQueryStringValue, getFileSuffix, verifyIsImage, verifyIsPackage } from '../../utils/index'
import { courseStartup, getCourseTemplate, saveActive, getLabCode, getUserd, getuserName, getLabToken, updateCourse, examQuestionDetail, examQuestionInfo, bankQuestionInfo, getActiveInfo, getRunCodeLanguage } from '../../api/modules/community'
import './index.less'
import Line from '../Line';
import config from '../../config';
// import MdEditor from '../MdEditor'
import Header from './Header';
import Tree from '../Tree'
import { getRepoStatus, getWebscoketUrl, refreshCourse, setRepoStatus } from '../../api/modules/repo'
import { mkdir, rename, rm } from '../../api/modules/fs';
import MoreMenu from '../MoreMenu';
import Tools from '../Tools';
// import GPT from '../GPT'
import CourseModal from '../CourseModal';
import ScoreModal from './ScoreModal';
import { v4 as uuid } from 'uuid';
import { read } from '../../api/modules/fs'
import { base64ToText, isJsonString } from './../..//utils'
// import Xterm from '../Xterm';
import Loading from '../Loading';
import ResourceManage from './ResourceManage';
import SharedDataContext from '../../utils/share';
// import PDFReader from '../PDFReader'; //PDF阅读器
import Sider from './Sider'; //侧边栏
import RightSider from './RightSider';
import LeftSider from './LeftSider';
import { fileTypes } from '../../utils/fileTypes';
import hljs from 'highlight.js';
import BlogDirectory from '../BlogDirectory'; //博客目录
import { Type, courseTypes, projectType, RunToolsTypes } from '../../utils/commonTypes'
// import Editor from '@ylzcc/editor'
import { getExperEnv, getTemplateById } from '../../api/modules/cli'
// import RhEditor from '../RhEditor';
// import EnvStart from '../EnvStart';
import { BehaviorCenter, SocketSchedule } from '../../utils/userBehaviorCenter';
import { useDisableCopyPaste } from '../../hooks/disableCopyPaste';

// import LibroEditor from '../LibroEditor';
import { ManaAppPreset, ManaComponents } from '@difizen/mana-app';
import { LibroJupyterModule } from '@difizen/libro-jupyter';
import { CloudLabLibroModule } from '../LibroEditor/module';
import { getActivityInfo, removeActivity, saveActivity } from '@/api/modules/activity';
import { activityInfo } from '@/api/modules/community';
import postRobot from 'post-robot'
import EnterWorkspaceLoading from '@/library/EnterWorkSpaceLoading';
import ContactUs from '@/library/ContactUs';
import { CCMVerifyMode } from '@/library/CodeTest';
import { useAIEnabled } from '@/hooks/useAIEnables';

const PDFReader = lazy(() => import('../PDFReader'));
const VideoPlayer = lazy(() => import('../VideoEditor/VideoPlayer'));
const CodeEditor = lazy(() => import('../CodeEditor'));
const Webview = lazy(() => import('../webview'));
const LibroEditor = lazy(() => import('../LibroEditor'))
const RhEditor = lazy(() => import('../RhEditor'));
const EnvStart = lazy(() => import('../EnvStart'));
const MdEditor = lazy(() => import('../MdEditor'));
const Editor = lazy(() => import('@ylzcc/editor'));
const Xterm = lazy(() => import('../Xterm'));
const VideoEditor = lazy(() => import('../VideoEditor'));
const ExamPaper = lazy(() => import('../ExamPaper'));
const Exam = lazy(() => import('../Exam'));

const { warning, confirm } = Modal;

const Layout: React.FC = () => {

    // 禁用复制粘贴剪切
    const { disable, cancel } = useDisableCopyPaste()

    // 考试传入的Labcode
    const examLabCode = getQueryStringValue("examLabcode")


    //Pod.yaml路径
    const location = getQueryStringValue("location")

    // modal：形态（0：学习，1：创作)
    const modal = getQueryStringValue("modal") || '0'

    // examType：考试的类型
    const examType = getQueryStringValue("examType")

    // 实操题进入的类型  exam mark 
    const examstatus = getQueryStringValue('examstatus')

    // 新增考试类型------
    // 工程项目类实操题  project
    const type = getQueryStringValue("type")?.toUpperCase()
    // 相应课程类型的子类型  exam preview mark
    const _projectType = getQueryStringValue("projectType")?.toUpperCase()

    // 学习课程 => 小节
    const chapter = decodeURIComponent(getQueryStringValue("chapter") || '')

    // 是否预览
    const isPreview = getQueryStringValue("preview")

    // 课程名称
    const courseName = getQueryStringValue('courseName') || '未命名'

    // 仓库
    const repo = getQueryStringValue('repo')

    // 用户
    const user = getQueryStringValue('key')

    // 用户id
    const userId = getQueryStringValue('userId') || user

    // 初次进入工作台：课程目录加载中，不显示缺省
    const [loadingCourseData, setLoadingCourseData] = useState(true)

    // 初次进入工作台：当前学习进度加载中，不显示缺省
    const [loadingSpeed, setLoadingSpeed] = useState(true)

    // 全局进入工作台之前的loading
    const [enterBeforeLoading, setEnterBeforeLoading] = useState(true)

    // 课程名称
    const [courseTitle, setCourseTitle] = useState(courseName)

    // 小节信息
    const [section, setSection] = useState<any>({})

    const [chapterId, setChapterId] = useState<string>()

    // 移动到这边
    const [selectedChapter, setSelectedChapter] = useState<any>()

    // 上一次的小节信息
    const [lastSectionPath, setLastSectionPath] = useState<any>({})

    // 侧边栏宽度
    const [sidebarW, setSidebarW] = useState(240)

    // 容器宽度
    const [containerW, setContainerW] = useState(0)

    // 侧边栏折叠，默认展开
    const [fold, setFold] = useState(false)
    // 课程信息
    const [info, setInfo] = useState<any>()

    // 容器
    const [containers, setContainers] = useState<any>([])

    // 是否正在预览
    const [preview, setPreview] = useState(false)

    // 学习状态，课程是否更新
    const [isUpdate, setIsUpdate] = useState(false)

    // xtermRef
    const [xtermRefs, setXtermRefs] = useState<any>({})

    //当前运行的sh
    const [xtermSh, setXtermSh] = useState('')

    // xterm执行、校验
    const [xtermAction, setXtermAction] = useState<any>()

    // textMarkdowmRef 
    const textMarkdowmRef: any = useRef()

    // 视频ref
    const videoPlayerRef: any = useRef()

    // 网页ref
    const webviewRef: any = useRef()

    // md编辑器ref
    const mdEditorRef: any = useRef()

    // 编辑器ref
    const editorRef: any = useRef()

    // 实验环境启动组件ref
    const envStartRef: any = useRef()

    // libro编辑器ref
    const libroEditorRef: any = useRef()

    // xterm的websocket地址
    const [xtermWSURL, setXtermWSURL] = useState('')

    // 容器相关信息
    const [pod, setPod] = useState<any>()

    // 环境下的全部容器
    const [podContainers, setPodContainers] = useState<any>([])

    // 环境下连接的容器
    const [podContainer, setPodContainer] = useState<any>()

    // 下一节path
    const [nextSection, setNextSection] = useState<any>()

    // 显示完成弹框
    const [finish, setFinish] = useState(false)

    //分数弹窗
    const [scoreShow, setScoreShow] = useState(false)

    //当前小节的的uuid
    const [activeId, setActiveId] = useState<any>()

    // 小节的活动配置信息
    const [activitiesConfig, setActivitiesConfig] = useState<any>()

    // 提交分数
    const [submitScore, setSubmitScore] = useState<any>(undefined)

    // 实验室code
    const [labCode, setLabCode] = useState<string>('')
    // 课程编码
    const [couseCode, setCourseCode] = useState('')
    // 课程类型
    const [courseType, setCourseType] = useState<any>()


    //导入弹窗是否显示
    const [cloneSuccess, setCloneSuccess] = useState(false)

    // 全局环境详细信息
    const [globalEnvPod, setGlobalEnvPod] = useState<any>({})
    // 环境状态  环境是启动中    0 表示启动中  1表示运行中
    const [globalEnvStatus, setGlobalEnvStatus] = useState()
    // 用户昵称
    const [creator, setCreator] = useState('')
    // 用户信息
    const [userInfo, setUserInfo] = useState({})

    //导入底部弹窗
    const [importBtn, SetImportBtn] = useState(false)

    // 显示博客目录（学习 => 无目录 => 不显示）
    const [showBlogDirectory, setShowBlogDirectory] = useState(true)

    // 实验环境列表数据
    const [envs, setEnvs] = useState<any>([])

    // 设置关闭网页id
    const [closeWebviewTab, setCloseWebviewTab] = useState<any>('')

    // 当前节的path
    const currentChapterPath = useRef<any>()
    // 当前节的id
    const currentChapterId = useRef<any>()
    // codeplayer列表支持的语言
    const [codePlayerLanguageList, setCodePlayerLanguageList] = useState([])

    const [getAiEnabled] = useAIEnabled()

    const fetchLanguageList = async () => {
        let list = await getRunCodeLanguage().catch(() => { })
        list = list?.map((item: any) => {
            return { label: item.dictCodeText, value: item.dictCode }
        })
        setCodePlayerLanguageList(list ?? [])
    }

    useEffect(() => {
        fetchLanguageList()
    }, [])

    // 获取用户昵称
    useEffect(() => {
        if (userId) {
            getuserName(userId).then(res => {
                setCreator(res?.nickname)
                setUserInfo(res)
            }).catch(() => { })
        }
    }, [userId])

    const debounceTime = useRef<any>(null)

    // 1考试时编码  examRecordCode   2创建考卷过程中编码  考卷编码  examCode   3题库中的编码 没有
    // 考试编码
    const examRecordCode = getQueryStringValue('examRecordCode')
    // 考卷编码
    const examCode = getQueryStringValue("examCode")

    //题目编码
    const questionCode = getQueryStringValue('questionCode')

    // 标题与编辑器同步  
    const syncTitle = (type: 'toEditor' | 'toHeader', title: string) => {
        // 博客和考试可以编辑标题，课程类型标题不可编辑
        const editable = (courseType === courseTypes.BLOG || courseType === courseTypes.EXPERIENCE ? true : false)
        if (editable) {
            if (type === 'toEditor') {
                editorRef?.current?.setTitle(title)
            } else {
                debounceTime.current && clearTimeout(debounceTime.current)
                debounceTime.current = setTimeout(() => {
                    if (title.length > 50) {
                        title = title.slice(0, 50)
                        editorRef?.current?.setTitle(title)
                    }
                    updateCourse({
                        code: couseCode,
                        name: title
                    }).then(res => {
                        setCourseTitle(title)
                    }).catch(() => { })
                }, 1000);
            }
        }
    }

    // 获取活动配置信息
    const fetchRead = (params: any) => {
        read({
            ...params,
            file: 'activities.json'
        }).then(res => {
            const data = res.data
            const _data = base64ToText(data)
            const _config = isJsonString(_data) ? JSON.parse(_data) : {}
            setActivitiesConfig(_config)
            window.localStorage.setItem('mark', _config.mark)
        }).catch(() => {
            setActivitiesConfig({
                mark: false
            })
            window.localStorage.setItem('mark', 'false')
        })
    }

    //学习完某一小节
    const successShowConfirm = () => {
        reportData('chapterProgress', {
            chapter: section?.path,
            status: 2   // (1进行中；2已完成)
        })
        const modal = Modal.confirm({
            title: '恭喜完成！',
            icon: <SvgIcon iconClass='success' fontSize='22px' />,
            content: nextSection?.path ? '恭喜你已经完成了当前小节的课程！请继续保持！' : '恭喜您已经完成了该课程的学习！',
            centered: true,
            className: 'success',
            closable: true,
            afterClose: () => {
                setFinish(false)
            },
            width: 400,
            footer: <div className='ant-modal-confirm-btns'>
                <Button type={nextSection?.path ? 'default' : 'primary'} onClick={() => {
                    if (nextSection?.path) {
                        modal?.destroy()
                    } else {
                        onBackHome()
                    }
                }}>{nextSection?.path ? '留在当前节' : '回到首页'}</Button>
                {
                    nextSection?.path ? <Button type='primary' onClick={onNextSection}>去下一节</Button> : <></>
                }
            </div>
        });
    };

    // 埋点上报统一所需要的数据
    useEffect(() => {
        if (!BehaviorCenter.hasFiled && labCode && couseCode && userId && repo) {
            BehaviorCenter.setField(labCode, couseCode, userId, repo)
        }
    }, [labCode, couseCode, userId, repo])
    // 课程学习模式需要上报数据 开始学习  // 接口发送
    useEffect(() => {
        if (section?.path && modal === '0' && courseType === courseTypes.COURSE) {
            reportData('chapterProgress', {
                chapter: section?.path,
                status: 1   // (1进行中；2已完成)
            })
        }
    }, [section?.path, courseType])

    const procressSocketSchedule = useRef<SocketSchedule | null>(null)
    // // 课程学习模式需要上报数据 开始学习  // websocket  增加博客 快速体验
    useEffect(() => {
        if (!procressSocketSchedule.current && modal === '0' && (courseType === courseTypes.COURSE || courseType === courseTypes.BLOG || courseTypes.EXPERIENCE) && examType !== '0' && labCode && couseCode && userId && repo && section?.path) {
            procressSocketSchedule.current = new SocketSchedule(labCode, couseCode, userId, repo, section?.path, section?.type)
        }
        return () => {
            procressSocketSchedule.current?.close()
            procressSocketSchedule.current = null
        }
    }, [section?.path, courseType, labCode, couseCode, userId, repo, modal, examType])

    // 仅执行一次
    const one = useRef(1)
    // 进入实验室累积加一
    useEffect(() => {
        if (one.current && labCode && userId && modal === '0' && courseType === courseTypes.COURSE) {
            BehaviorCenter.addTask('labVisitCount', {
                labCode: labCode,
                userId: userId
            })
            one.current = 0
        }
    }, [labCode, courseType]);

    // 数据埋点
    const reportData = (event: string, data: object) => {
        BehaviorCenter.addTask(event, data)
    }

    // 回到首页
    const onBackHome = () => {
        window.location.replace(config.osURL)
    }

    // 去下一节
    const onNextSection = () => {
        setFinish(false)
        const nextSection = lookNextSection(courseInfo, section?.path)
        setSelectedChapter(nextSection)
        setCurrentChapter(nextSection)
        Modal.destroyAll();
    }

    //还有题目未完成弹窗
    const scoreConfirm = () => {
        warning({
            title: '温馨提示',
            icon: <SvgIcon iconClass='warning' fontSize='22px' />,
            content: '步骤内还有未完成的活动，请完成后再进入下一步',
            centered: true,
            className: 'success',
            width: 400,
            okText: '我知道了',

            onOk() {

            },
        });
    };

    //校验失败弹窗
    const errValiteConfirm = () => {
        warning({
            title: '校验错误！',
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: '请按照课程内容完成操作，操作并校验正确后才可进入下一题。',
            className: 'warn2',
            centered: true,
            width: 400,
            okText: '我知道了',

            onOk() {

            },
        });
    };

    // 获取课程信息
    const fetchCourse = () => {
        if (examType === '0') {
            console.log('考试');

        } else {
            getCourseTemplate({ repo }).then(res => {
                setInfo({
                    status: res === '' ? 0 : 1
                })
            })
        }
    }

    // 使用模板初始化课程目录
    const onStartup = (temp: string) => {
        console.log('examType =>', examType);

        // 是否考试
        if (examType === '0') {
            console.log('考试');

        } else {
            courseStartup({
                repo,
                temp
            }).then(() => {
                // 初始化成功，关闭弹框
                setInfo({
                    status: 1
                })
            })
        }
    }

    // 预览功能
    const onPreview = () => {
        if (!preview) {
            window.postMessage({
                type: 'pushContainer',
                component: 'Markdown',
            })
        } else {
            // 关闭预览
            for (const iterator of containers) {
                iterator.components = iterator?.components?.filter((item: any) => item?.id !== '_Markdown_')
            }
            const _containers = containers?.filter((item: any) => item?.components?.length)
            setContainers([..._containers])
        }
        setPreview(!preview)
    }

    // 编码测验题执行处理函数
    // data当前题型数据 finish 打开代码编辑的回调  codeChange 代码变化 resChange 运行结果变化
    const codePlayerConnect = (data: any, finish: Function, codeChange: (code: string) => void, resChange: (pass: boolean) => void) => {
        const platform = (data.verifyMode === CCMVerifyMode.INPUT_OUTPUT || data.verifyMode == undefined) ? 'quiz' : 'runner'
        const win = window.open(platform === 'quiz' ? config.codeTestURL : `${config.codePlayerURL}/embedded`)
        if (platform === 'quiz') {
            postRobot.send(win, "send", {
                title: data?.topic ?? '',
                content: data?.title ?? '',
                initCode: data?.initCode ?? data?.code,
                code: data.code,
                lang: data.codeLanguage,
                cases: data.example,
                submitCases: data.testExample,
                aiEnabled: getAiEnabled('quiz', 'editorActivity'),
            }).then(() => {
                finish?.()
                postRobot.on("change", { window: win }, async function (event: any) {
                    codeChange?.(event.data ?? '')
                })
                postRobot.on("result", { window: win }, async function (event: any) {
                    resChange?.(event.data?.pass)
                })
            }).catch(() => { })
        } else {
            const {
                topic: title = '',
                title: content = '',
                codeLanguage: lang = '',
                code = ''
            } = data
            let codes = [code]
            if (lang === 'html') {
                try {
                    const all = JSON.parse(code)
                    codes = [all.html, all.css, all.js]
                } catch (error) { }
            }
            postRobot.send(win, 'send', {
                title, content, lang, code: codes, aiEnabled: getAiEnabled('run', 'editorActivity'), run: false, dark: true,
            }).then(() => {
                finish?.()
                postRobot.on("change", { window: win }, async function (event: any) {
                    let code = event?.data?.[0] ?? ''
                    if (lang === 'html') {
                        try {
                            code = JSON.stringify({
                                html: event?.data?.[0] ?? '',
                                css: event?.data?.[1] ?? '',
                                js: event?.data?.[2] ?? '',
                            })
                        } catch (error) { }
                    }
                    codeChange?.(code)
                })
            }).catch(() => { })
        }
    }

    // 富文本编辑器 examType === '0' && type === Type.PROJECT 
    let _RichEditor: any = {
        id: '_RichEditor',
        title: courseTitle,
        component: RhEditor,
        icon: <SvgIcon iconClass={'editor'} fontSize='14px'></SvgIcon>,
        props: {
            wsUrl: `${config.wsURL}?key=${user}&repo=${repo}&path=${encodeURIComponent(section?.path || '')}&component=file&file=index.md`,
        }
    }
    // console.log('🚀', `${config.wsURL}?key=${user}&repo=${repo}&path=${encodeURIComponent(section?.path || '')}&component=file&file=index.md`)
    // 文档编辑器
    let _Editor: any = {
        id: '_Editor',
        title: examType === '0' && courseTitle ? courseTitle : section?.path,
        component: Editor,
        icon: <SvgIcon iconClass={'editor'} fontSize='14px'></SvgIcon>,
        props: {
            // 让类型为video的连接为空会影响editor中的first
            wsUrl: section?.type === 'video' ? '' : `${config.wsURL}?key=${user}&repo=${repo}&path=${encodeURIComponent(section?.path || '')}&component=file&file=index.md`,
            editable: modal === '1',
            templateInfo: section?.template,
            apiUrl: config.baseURL,
            ref: editorRef,
            xternUrl: xtermWSURL,
            title: examType === '0' || courseType === courseTypes.BLOG || courseType === courseTypes.EXPERIENCE ? (courseTitle === '未命名' ? '' : courseTitle) : section?.title,
            titleOnChange: (title: string) => {
                syncTitle('toHeader', title)
            },
            titleEditable: courseType === courseTypes.BLOG || courseType === courseTypes.EXPERIENCE ? true : false,
            onExec: (value: any) => {
                try {
                    const data = JSON.parse(value)
                    setCodePlayerRunMsg({ value: data })
                } catch (error) {
                    setCodePlayerRunMsg({ value })
                }
                // if (isJsonString(value) && isCodePlayerRun(JSON.parse(value))) return
                // if (section?.template?.experEnv) {
                //     window.postMessage({
                //         type: 'pushContainer',
                //         component: 'Xterm',
                //         value: {
                //             is: true
                //         }
                //     })
                // }
                // if (isJsonString(value)) {
                //     // 示例演示的执行
                //     const _value = JSON.parse(value)
                //     if (_value?.type === 'experiment') {
                //         setXtermAction({
                //             type: 'exec',
                //             value: _value?.codePath ? `sh ${_value?.codePath}` : _value?.code
                //         })
                //         return
                //     }
                // }
                // setXtermAction({
                //     type: 'exec',
                //     value
                // })
            },
            codePlayerLanguageList,
            onBack: () => {
                onChangeEditor()
            },
            // 不是考试  学习模式 有完成按钮
            finish: courseType === courseTypes.COURSE && modal === '0' ? () => {
                setFinish(true)
            } : undefined,
            // 不是考试  学习模式 需要上报
            reportData: courseType === courseTypes.COURSE && modal === '0' ? (data: any) => {
                reportData('chapterActivity', {
                    chapter: currentChapterPath.current,
                    activityId: data?.id
                })
            } : undefined,
            getActivity: async (id: string) => {
                if (modal === '1' || isPreview === '1') {
                    return await getActivityInfo(id, {
                        file: 'activity.json',
                        path: currentChapterPath.current,
                        repo
                    })
                } else {
                    const res = await activityInfo({
                        activityId: id,
                        chapterId: currentChapterId.current,
                        repo,
                        userId
                    })
                    const topicConfig = res?.topicConfig ? JSON.parse(res?.topicConfig) : null
                    return topicConfig ? { ...topicConfig, initCode: topicConfig?.code, topicResult: res?.topicResult, topicStatus: res?.topicStatus } : await getActivityInfo(id, {
                        file: 'activity.json',
                        path: currentChapterPath.current,
                        repo
                    })
                }
            },
            removeActivity: async (id: string) => {
                return await removeActivity({
                    path: currentChapterPath.current,
                    repo,
                }, {
                    file: 'activity.json',
                    id
                })
            },
            // 看了这个组件，这个方法好像没有调用，先不传
            // updateActivity: async (value: any) => {
            //     return await saveActivity({
            //         path: currentChapterPath.current,
            //         repo,
            //     }, value)
            // },
            submitActivity: (value: any) => {
                if (modal === '1' || isPreview === '1') return
                setSubmitScore(value)
            },
            codePlayerConnect: codePlayerConnect,
            // codePlayerConnect: {
            //     open: (): WindowProxy | null => {
            //         return window.open(config.codeTestURL)
            //     },
            //     send: async (win: WindowProxy, data: any) => {
            //         return await postRobot.send(win, "send", {
            //             title: data?.topic ?? '',
            //             content: data?.title ?? '',
            //             initCode: data?.initCode ?? data?.code,
            //             code: data.code,
            //             lang: data.codeLanguage,
            //             cases: data.example,
            //             submitCases: data.testExample,
            //         })
            //     },
            //     listenChange: (win: WindowProxy, callback?: Function) => {
            //         return postRobot.on("change", { window: win }, function (event: any) {
            //             callback?.(event.data)
            //             return Promise.resolve()
            //         })
            //     },
            //     listenResult: (win: WindowProxy, callback?: Function) => {
            //         return postRobot.on("result", { window: win }, function (event: any) {
            //             callback?.(event.data)
            //             return Promise.resolve()
            //         })
            //     }
            // },
            showTemplate: () => {
                confirm({
                    title: '温馨提示',
                    icon: <ExclamationCircleFilled rev={undefined} />,
                    content: '您还未配置实验环境，无法直接执行，请先配置。',
                    okText: '去配置',
                    centered: true,
                    className: 'warn2',
                    width: 400,
                    cancelText: '我知道了',
                    onOk() {
                        editPath()
                    }
                });
            }
        }
    }

    // markdown编辑器
    let _MdEditor: any = {
        id: '_MdEditor',
        title: examType === '0' && courseTitle ? courseTitle : section?.path,
        component: MdEditor,
        icon: <SvgIcon iconClass={'editor'} fontSize='14px'></SvgIcon>,
        props: {
            ref: mdEditorRef,
            params: {
                repo,
                user,
                path: section?.path,
                sign: ''
            },
            wsUrl: `${config.wsURL}?key=${user}&repo=${repo}&path=${encodeURIComponent(section?.path || '')}&component=file&file=index.md`,
            language: 'md',

            userKey: user,
            category: section?.type,
            template: section?.template,
            courseCode: couseCode,
            changeEv: () => {
                editPath()
            }
        }
    }

    // 代码编辑器
    let _CodeEditor: any = {
        id: 'index.md',
        title: section?.path,
        component: CodeEditor,
        props: {
            wsUrl: `${config.wsURL}?key=${user}&repo=${repo}&path=${encodeURIComponent(section?.path || '')}&component=file&workdir=1&file=`,

            language: 'md',
            userKey: user
        }
    }

    // Libro 编辑器
    let _LibroEditor: any = {
        id: '_LibroEditor',
        title: examType === '0' && courseTitle ? courseTitle : section?.path,
        component: LibroEditor,
        props: {
            ref: libroEditorRef,
            wsUrl: `${config.wsURL}?key=${user}&repo=${repo}&path=${encodeURIComponent(section?.path)}&component=file&file=index.md`,
            language: 'ipynb',
            userKey: user,
            name: courseTitle + '.ipynb',
            isPreview: modal === '0',
        }
    }

    // 视频播放器
    let _VideoPlayer: any = {
        id: '_VideoPlayer_',
        title: section?.path,
        icon: <SvgIcon iconClass={'videoplayer'} fontSize='14px'></SvgIcon>,
        component: VideoPlayer,
        props: {
            onExec: (value: any) => {
                setCodePlayerRunMsg({ value, taskId: value?.id })
                // setXtermAction({
                //     type: 'exec',
                //     value: value?.codePath ? `sh ${value?.codePath}` : value?.code
                // })
            },
            onVideoEnded: () => {
                // 学习模态
                if (modal !== '1') {
                    setFinish(true)
                }
            },
            onSubmitActivity: (value: any) => {
                if (modal === '1' || isPreview === '1') return
                setSubmitScore(value)
            }
        }
    }

    // 视频编辑器（新）
    let _VideoEditor: any = {
        id: '_VideoEditor_',
        title: section?.path,
        icon: <SvgIcon iconClass={'videoplayer'} fontSize='14px'></SvgIcon>,
        component: VideoEditor,
        props: {
            onExec: (value: any) => {
                setCodePlayerRunMsg({ value, taskId: value?.id })
                // setXtermAction({
                //     type: 'exec',
                //     value: value?.codePath ? `sh ${value?.codePath}` : value?.code
                // })
            }
        }
    }

    // GPT
    // let _GPT: any = {
    //     id: 'GPT',
    //     title: 'ChatGPT',
    //     component: GPT,
    //     props: {
    //         socket: `${config.gptURL}?repo=${repo}&component=gpt`
    //     }
    // }

    // 文本播放阅读器
    let _Markdown: any = {
        id: '_Markdown_',
        title: examType === '0' && courseTitle ? courseTitle : section?.path,
        component: Markdown,
        icon: <SvgIcon iconClass={'markdown'} fontSize='14px'></SvgIcon>,
        props: {
            wsUrl: `${config.wsURL}?key=${user}&repo=${repo}&path=${encodeURIComponent(section?.path || '')}&component=file&file=index.md`,
            type: 'text',
            mardownKey: user,
            ifExam: examType === '0' || courseType === courseTypes.BLOG || courseType === courseTypes.EXPERIENCE ? true : false,
            modal: Number(modal),
            ref: textMarkdowmRef,
            ifRequest: () => {
                scoreConfirm()
            },
            execDemo: (data: any) => {
                setCodePlayerRunMsg({ value: data })
                // // 走codeplay流程
                // if (isCodePlayerRun(data)) return
                // // 走执行shell脚本流程
                // if (section?.template?.experEnv) {
                //     window.postMessage({
                //         type: 'pushContainer',
                //         component: 'Xterm',
                //         value: {
                //             is: true
                //         }
                //     })
                // }
                // if (data.codeLanguage === 'shell') {
                //     setXtermAction({
                //         type: 'exec',
                //         value: data?.codePath ? `sh ${data?.codePath}` : data?.code
                //     })
                // }
            },
            getActivity: async (id: string) => {
                const initData = await getActivityInfo(id, {
                    file: 'activity.json',
                    path: section?.path,
                    repo
                }).catch(() => { })
                if (!initData) return
                if (modal === '1' || isPreview === '1') {
                    return initData
                } else {
                    // question     问答
                    // experiment   示例演示  没有状态
                    // validate     编码测验
                    // const { type } = initData
                    // if (type === 'experiment') return initData
                    // 学习态先查看该接口
                    const res = await getActiveInfo({
                        activityId: id,
                        chapterId: section?.describe?.chapterId ?? CryptoJS.MD5(repo + section?.path).toString(),
                        repo,
                        userId
                    }).catch(() => { })
                    if (res && res.topicConfig) {
                        const _res = JSON.parse(res.topicConfig)
                        return { ..._res, initCode: initData?.code, topicResult: res.topicResult, topicStatus: res.topicStatus }
                    } else {
                        return initData
                    }
                }
            },
            // topicStatus: '0' | '1'  0 失败 1 成功  undefined 没有说成功或者失败
            updateActivity: async (activityId: string, topicConfig: string, topicResult: string, topicStatus?: '0' | '1') => {
                // 创作态不实时保存
                if (modal === '1') return
                await saveActive({
                    chapterId: section?.describe?.chapterId ?? CryptoJS.MD5(repo + section?.path).toString(),
                    repo,
                    userId,
                    activityId,
                    topicConfig,
                    topicResult,
                    topicStatus
                })
            },
            codePlayerConnect: codePlayerConnect,
            // codePlayerConnect: {
            //     open: (): WindowProxy | null => {
            //         return window.open(config.codeTestURL)
            //     },
            //     send: async (win: WindowProxy, data: any) => {
            //         return await postRobot.send(win, "send", {
            //             title: data?.topic ?? '',
            //             content: data?.title ?? '',
            //             initCode: data?.initCode ?? data?.code,
            //             code: data.code,
            //             lang: data.codeLanguage,
            //             cases: data.example,
            //             submitCases: data.testExample,
            //         })
            //     },
            //     listenChange: (win: WindowProxy, callback?: Function) => {
            //         return postRobot.on("change", { window: win }, function (event: any) {
            //             callback?.(event.data)
            //             return Promise.resolve()
            //         })
            //     },
            //     listenResult: (win: WindowProxy, callback?: Function) => {
            //         return postRobot.on("result", { window: win }, function (event: any) {
            //             callback?.(event.data)
            //             return Promise.resolve()
            //         })
            //     }
            // },
            getInstruct: (type: string, value: string) => {
                if (type === 'exec') {
                    setCodePlayerRunMsg({ value })
                } else if (type === 'copy') {
                    message.destroy()
                    message.success('复制成功')
                }
            },
            overPop: () => {
                // console.log('完成了吗');

                if (modal === '0') {
                    setFinish(true)
                }
            },
            verify: (value: any) => {
                // 暂时注释 已经没有校验，但是md里面有逻辑判断这个函数是否存在，会执行，看了md代码，没什么用，只是执行了成功还是失败，会打印在shell中
                // setXtermAction({
                //     type: 'validate',
                //     value: {
                //         type: 0,
                //         value
                //     }
                // })
            },
            nextVerify: (value: any) => {
                // 同上
                // setXtermAction({
                //     type: 'validate',
                //     value: {
                //         type: 1,
                //         value
                //     }
                // })
            },
            addActive: (value: any, addID: any) => {
                setSubmitScore({
                    score: value,
                    id: addID
                })
            },
            // 不是考试  学习模式 需要上报
            reportData: courseType === courseTypes.COURSE && modal === '0' ? (data: any) => {
                reportData('chapterActivity', {
                    chapter: section?.path,
                    activityId: data?.id
                })
            } : undefined
        }
    }

    // 考试编辑器
    let _ExamPaper: any = {
        id: '_ExamPaper_',
        title: section?.path,
        component: ExamPaper,
        icon: <SvgIcon iconClass={'exam'} fontSize='14px'></SvgIcon>,
        props: {
            wsUrl: `${config.wsURL}?key=${user}&repo=${repo}&path=${encodeURIComponent(section?.path || '')}&component=file&file=index.md`,
            modal,
            labCode,
            repo
        }
    }

    // xterm工具 Shell
    let _Xterm: any = {
        id: '_XtermShell_',
        title: 'Shell',
        component: Xterm,
        icon: <SvgIcon iconClass={'shell'} fontSize='14px'></SvgIcon>,
        props: {
            id: '_XtermShell_Shell',
            conn: xtermWSURL,
            path: section?.path,
            valiateReturn: (value: any) => {
                if (value) {
                    message.destroy()
                    message.success('验证成功！')

                } else {
                    message.destroy()
                    message.error('验证失败,请重新执行！')
                }
            },
            nextReturn: (value: any) => {
                if (value) {
                    textMarkdowmRef?.current?.next()
                } else {
                    errValiteConfirm()
                }
            },
            initExec: () => {
                if (xtermExecTask?.current) {
                    xtermExecTask.current()
                }
            }
        },
        upperRightComponent: <Button type='primary' ghost onClick={() => {
            window.postMessage({
                type: 'pushContainer',
                component: 'Webview',
                value: {
                    path: 'https://oscollegedoc.helplook.com/docs/shell-ji-chu-shi-yong-shuo-ming',
                    name: 'Shell使用说明',
                }
            })
        }}>使用说明</Button>
    }

    // 网页
    let _Webview: any = {
        id: '_Webview_',
        title: '网页',
        component: Webview,
        props: {
            ref: webviewRef,
            pod: pod,
            modal: modal
        }
    }

    // PDF阅读器
    let _PDFReader: any = {
        component: PDFReader,
        icon: <SvgIcon iconClass='pdf' fontSize='14px'></SvgIcon>,
        props: {
            url: `${config.baseURL}/fs/content?repo=${repo}&path=${encodeURIComponent(section?.path || '')}&workdir=true&file=`
        }
    }

    // 环境启动组件
    let _EnvStart: any = {
        id: '_EnvStart_',
        title: '实验环境',
        icon: <SvgIcon iconClass='env-icon' fontSize='14px'></SvgIcon>,
        component: EnvStart,
        props: {
            ref: envStartRef
        }
    }


    //#region 
    // ------------执行任务相关----------------
    // 用来触发codePlayerRun，避免里面的变量无法使用
    const [codePlayerRunMsg, setCodePlayerRunMsg] = useState<any>(null)
    useEffect(() => {
        if (codePlayerRunMsg) {
            codePlayerRun(codePlayerRunMsg?.value, codePlayerRunMsg?.taskId)
            setCodePlayerRunMsg(null)
        }
    }, [codePlayerRunMsg])

    // 所有codePlayer和shell执行的入口，主要是示例演示和md旧编辑器执行
    // value的值需要有code
    // 任务执行后会触发任务执行完毕的全局事件,如果任务执行的时候有提供任务id
    const codePlayerRun = (value: string | object, taskId?: string) => {
        // 判断是在shell中执行还是codeplayer   ['shell', 'bash']为同一个语言，判断两个是为了兼容
        // 如果为string,说明要在shell中执行的
        let isShell = (typeof value === 'string' || ['shell', 'bash'].includes((value as any)?.codeLanguage ?? ''))
        const finish = () => {
            if (taskId) {
                window.postMessage({
                    type: 'codePlayer-task',
                    value: {
                        taskId
                    }
                })
            }
        }
        if (isShell) {
            let execCode
            if (typeof value === 'string') {
                execCode = value
            } else {
                const { code, codePath } = value as any
                execCode = codePath ? `sh ${codePath}` : code
            }
            setXtermAction({
                type: 'exec',
                value: execCode,
                taskId,
            })
        } else {
            const { code, codeLanguage, template } = value as any
            // 先判断当前是否有环境
            if (template === 'follow' && !judegEnv()) return
            // 判断是否需要启动环境或启动环境
            judegeEnvStart(() => {
                window.postMessage({
                    type: 'pushContainer',
                    component: 'Webview',
                    value: {
                        path: `${config?.codePlayerURL}/embedded`,
                        name: 'CodePlayer',
                        value: {
                            code: [code],
                            lang: codeLanguage,
                            run: true,
                            needPod: template === 'follow'
                            // nameSpace: has ? undefined : config.nameSpace,
                            // podName: has ? undefined : pod,
                            // workspace: has ? undefined : `/home/community/${section?.path}`
                        },
                        customIcon: 'codeplayer'
                    }
                })
                finish()
            }, template === 'default')
        }
    }

    // codePlayerRun启动前判断当前是否设置环境
    const judegEnv = () => {
        if (!section?.template?.experEnv) {
            Modal.destroyAll()
            if (modal === '1') {
                confirm({
                    title: '温馨提示',
                    icon: <ExclamationCircleFilled rev={undefined} />,
                    content: '您还未配置实验环境，无法直接执行，请先配置。',
                    okText: '去配置',
                    centered: true,
                    className: 'warn2',
                    width: 400,
                    cancelText: '我知道了',
                    onOk() {
                        editPath()
                    }
                });
            } else {
                warning({
                    title: '温馨提示',
                    icon: <ExclamationCircleFilled rev={undefined} />,
                    content: '该课程暂无实验环境，无法执行！',
                    okText: '我知道了',
                    centered: true,
                    className: 'warn2',
                    width: 400,
                    cancelText: '我知道了',
                    onOk() { }
                });
            }
            return false
        }
        return true
    }

    // 监听启动执行任务启动环境后的回调
    useEffect(() => {
        const handle = (e: MessageEvent) => {
            const { type, value } = e.data
            if (type === 'execTaskFinish') {
                codePlayerExecTask.current?.()
            }
        }
        window.addEventListener('message', handle)
        return () => {
            window.removeEventListener('message', handle)
        }
    }, [])

    // codeplayer延时任务
    const codePlayerExecTask = useRef<any>()
    // codePlayerRun判断环境是否已经启动  如果已经启动则执行该任务，没有启动则在启动环境后执行该任务
    const judegeEnvStart = (callback: Function, througth: boolean = false) => {
        if (!througth && !pod) {
            // 未启动，需要提示
            Modal.destroyAll()
            Modal.confirm({
                title: "温馨提示",
                content: '您还未启动环境，请先启动环境后再点击执行。',
                cancelText: '我知道了',
                okText: '启动环境',
                centered: true,
                width: 400,
                // getContainer: videoPlayerRef?.current?.getFullScreenActive() ? document.getElementById('video-player')! : document.body,
                onOk(close) {
                    close()
                    // 直接启动环境：在启动环境组件里监听该消息
                    window.postMessage({
                        type: 'startupEnv',
                        value: {
                            execTask: true,   // 表明这是一个执行任务，忽略其他启动环境后的逻辑
                        }
                    })
                    //  环境启动后再执行该任务
                    codePlayerExecTask.current = () => {
                        callback()
                        codePlayerExecTask.current = null
                    }
                }
            })
        } else {
            callback()
        }
    }
    // ------------执行任务相关----------------
    //#endregion



    // 文本型：切换新旧编辑器
    const onChangeEditor = () => {
        rename({
            repo: params?.get('repo'),
            key: params?.get('key'),
            data: {
                title: selectedChapter?.title,
                path: selectedChapter?.path,
                parent: selectedChapter?.parent,
                describe: {
                    ...selectedChapter?.describe,
                    editMode: section?.editMode === 'new' ? '' : 'new'
                }
            }
        }).then(() => {
            setSection(
                {
                    ...section,
                    editMode: section?.editMode === 'new' ? '' : 'new'
                }
            )
        })
    }

    // 处理选中小节容器展示
    const handleSection = async () => {
        if (section?.path && (section?.type === 'text' || section?.type === 'video' || section?.type === 'notebook' || section?.type === 'exam')) {
            const _courseName = courseTitle
            if ((examType === '0' || courseType === courseTypes.BLOG || courseType === courseTypes.EXPERIENCE) && _courseName) {
                _Editor.title = _courseName
            }
            // 与上一小节相同环境设置，延用上次的环境资源，不关闭shell
            let shellArr: any = []
            if (containers?.length && section?.template?.experEnv === lastSectionPath?.template?.experEnv && pod) {
                shellArr = containers?.map((v: any) => {
                    const _components = v?.components?.filter((v: any) => v?.id?.startsWith('_XtermShell_'))
                    return _components
                })
            }
            let containers1: any
            let containers2: any
            if (modal === '1') {
                // 创作
                if (section?.type === 'video') {
                    containers1 = {
                        current: _VideoEditor.id,
                        components: [_VideoEditor]
                    }
                } else if (section?.type === 'notebook') {
                    containers1 = {
                        current: _LibroEditor.id,
                        components: [_LibroEditor]
                    }
                } else if (section?.type === 'text') {
                    // 针对项目实操题 使用富文本
                    if (examType === '0' && type === Type.PROJECT) {
                        containers1 = {
                            current: _RichEditor.id,
                            components: [_RichEditor]
                        }
                    } else {
                        if (section?.editMode === 'new') {
                            containers1 = {
                                current: _Editor.id,
                                components: [_Editor]
                            }
                        } else {
                            setPreview(true)
                            containers1 = {
                                current: _MdEditor.id,
                                components: [_MdEditor]
                            }
                            containers2 = {
                                current: _Markdown.id,
                                components: [_Markdown]
                            }
                        }
                    }
                } else if (section?.type === 'exam') {
                    containers1 = {
                        current: _ExamPaper.id,
                        components: [_ExamPaper]
                    }
                }
            } else {
                // 学习
                if (section?.type === 'video') {
                    containers1 = {
                        current: _VideoPlayer.id,
                        components: [_VideoPlayer]
                    }
                } else if (section?.type === 'notebook') {
                    containers1 = {
                        current: _LibroEditor.id,
                        components: [_LibroEditor]
                    }
                } else if (section?.type === 'exam') {
                    containers1 = {
                        current: _ExamPaper.id,
                        components: [_ExamPaper]
                    }
                } else {
                    // 针对工程项目类实操题 使用富文本
                    if (examType === '0' && type === Type.PROJECT) {
                        containers1 = {
                            current: _RichEditor.id,
                            components: [_RichEditor]
                        }
                    } else {
                        if (section?.editMode === 'new') {
                            containers1 = {
                                current: _Editor.id,
                                components: [_Editor]
                            }
                        } else {
                            containers1 = {
                                current: _Markdown.id,
                                components: [_Markdown]
                            }
                        }
                    }
                }
                // if (!shellArr?.length && section?.template?.experEnv) {
                //     containers2 = {
                //         current: _EnvStart.id,
                //         components: [_EnvStart]
                //     }
                // }
            }
            // 针对工程项目类实操题，默认直接启动环境（学生考试中除外）
            if (examType === '0' && type === Type.PROJECT && _projectType !== projectType.EXAM && section?.template?.experEnv) {
                window.postMessage({
                    type: 'startupEnv',
                    value: {
                        waitTempRunInfo: true
                    }
                })
            }
            // 博客、快速体验  在学习模式且有环境的情况下打开环境
            if (examType !== '0' && modal === '0' && (courseType === courseTypes.BLOG || courseType === courseTypes.EXPERIENCE) && section?.template?.experEnv) {
                // 等待启动信息回来再启动
                window.postMessage({
                    type: 'startupEnv',
                    value: {
                        waitTempRunInfo: true
                    }
                })
            }
            if (shellArr?.length) {
                shellArr?.forEach((v: any, index: number) => {
                    if (!v?.length) return
                    if (index === 0) {
                        containers1.components = [...containers1.components, ...v]
                    } else if (index === 1) {
                        if (containers2) {
                            containers2.components = [...containers2.components, ...v]
                        } else {
                            containers2 = {
                                current: v[0].id,
                                components: v
                            }
                        }
                    }
                })
            } else {
                setXtermRefs({})
            }
            setContainers(containers2 ? [containers1, containers2] : [containers1])
        } else {
            setContainers([])
        }
        // 让编辑器滚动到顶部
        const editorEl = document.getElementById('ylz-editor-container')
        editorEl?.scrollTo({ top: 0 })
        setLoadingSpeed(false)
    }


    // sterm 延迟执行的任务，因为有时候执行的时候xterm还没有渲染出来,里面目前是一个函数
    const xtermExecTask = useRef<any>()
    // xterm执行、校验   
    useEffect(() => {
        if (xtermAction) {
            if (!section?.template?.experEnv) {
                Modal.destroyAll()
                if (modal === '1') {
                    confirm({
                        title: '温馨提示',
                        icon: <ExclamationCircleFilled rev={undefined} />,
                        content: '您还未配置实验环境，无法直接执行，请先配置。',
                        okText: '去配置',
                        centered: true,
                        className: 'warn2',
                        width: 400,
                        cancelText: '我知道了',
                        onOk() {
                            editPath()
                        }
                    });
                } else {
                    warning({
                        title: '温馨提示',
                        icon: <ExclamationCircleFilled rev={undefined} />,
                        content: '该课程暂无实验环境，无法执行！',
                        okText: '我知道了',
                        centered: true,
                        className: 'warn2',
                        width: 400,
                        cancelText: '我知道了',
                        onOk() {

                        }
                    });
                }
                setXtermAction(null)
                return
            }
            if (!pod) {
                // 未启动，需要提示
                Modal.destroyAll()
                Modal.confirm({
                    title: "温馨提示",
                    content: '您还未启动环境，请先启动环境后再点击执行。',
                    cancelText: '我知道了',
                    okText: '启动环境',
                    centered: true,
                    width: 400,
                    // getContainer: videoPlayerRef?.current?.getFullScreenActive() ? document.getElementById('video-player')! : document.body,
                    onOk(close) {
                        close()
                        // 直接启动环境：在启动环境组件里监听该消息
                        window.postMessage({
                            type: 'startupEnv'
                        })
                    },
                    onCancel() {
                        setXtermAction(null)
                    }
                })
                return
            }
            const keys = Object.keys(xtermRefs)
            if (keys?.length) {
                let _id: any
                const index = containers?.findIndex((v: any) => keys?.includes(v?.current))
                if (index > -1) {
                    _id = containers[index]?.current
                } else {
                    // 寻找xterm，设置选中
                    for (const iterator of containers) {
                        const components = iterator?.components
                        const index = components?.findIndex((v: any) => v?.id.startsWith('_XtermShell_'))
                        if (index > -1) {
                            _id = components[index]?.id
                            iterator.current = _id
                            break
                        }
                    }
                    setContainers([...containers])
                }
                const value = xtermAction?.value
                const _ref = xtermRefs[_id]?.current
                if (xtermAction?.type === 'exec') {
                    if (_ref) {
                        _ref.execCode(value)
                        if (xtermAction?.taskId) {
                            window.postMessage({
                                type: 'codePlayer-task',
                                value: {
                                    taskId: xtermAction?.taskId
                                }
                            })
                        }
                    } else {
                        xtermExecTask.current = () => {
                            xtermRefs[_id]?.current?.execCode(value)
                            xtermExecTask.current = null
                            if (xtermAction?.taskId) {
                                window.postMessage({
                                    type: 'codePlayer-task',
                                    value: {
                                        taskId: xtermAction?.taskId
                                    }
                                })
                            }
                        }
                    }
                } else if (xtermAction?.type === 'validate') {
                    _ref?.sendValiate(value?.type, value?.value)
                }
                // 清空命令
                setXtermAction(null)
            } else {
                // 不存在xterm，打开一个
                window.postMessage({
                    type: 'pushContainer',
                    component: 'Xterm',
                    value: {
                        tool: "shell"
                    }
                })
            }
        }
    }, [xtermAction, xtermRefs])



    // 跟容器的交互
    const handleContainer = (e: any) => {
        const data = e.data
        if (data?.type === "import") {
            SetImportBtn(data.value)
            setCloneSuccess(data.value)
            mdEditorRef?.current?.update()
            editorRef?.current?.update()
            return
        }
        if (data?.type === "pushContainer") {
            let _component: any
            const value = data?.value
            const component = data?.component
            switch (component) {
                case "Editor":
                    if (!isComponentExist(_Editor.id)) {
                        _component = _Editor
                    }
                    break;
                case "VideoEditor":
                    if (!isComponentExist(_VideoEditor.id)) {
                        _component = _VideoEditor
                    }
                    break;
                case "MdEditor":
                    // 针对工程项目实操题处理
                    if (examType === '0' && type === Type.PROJECT) {
                        if (!isComponentExist(_RichEditor.id)) {
                            _component = _RichEditor
                        }
                    } else {
                        if (!isComponentExist(_MdEditor.id)) {
                            _component = _MdEditor
                        }
                    }
                    break;
                case "LibroEditor":
                    if (!isComponentExist(_LibroEditor.id)) {
                        if (value) {
                            _LibroEditor.title = value?.path;
                            _LibroEditor.props = {
                                ..._LibroEditor.props,

                                // value.url 区分是课程资源还是我的资源
                                wsUrl: value?.url ? `${config.wsURL}?key=${user}&repo=${repo}&_private=true&path=${encodeURIComponent(value.url)}&component=file&workdir=1&file=${encodeURIComponent(value?.path)}` : _LibroEditor.props.wsUrl + encodeURIComponent(value?.path),
                                // language: value?.path === 'index.md' ? 'md' : getFileSuffix(value?.title),
                                name: value.path
                            }
                        }
                        _component = _LibroEditor
                    }
                    break;
                case "CodeEditor":
                    if (!isComponentExist(value?.path + value?.url)) {
                        _CodeEditor.id = value?.path + value?.url
                        if (value?.path !== 'index.md') {
                            _CodeEditor.title = value?.path
                            // const type = value?.title?.match(/\.(.+)$/)?.[1]
                            const type = value?.title?.substring(value?.title?.lastIndexOf('.') + 1)
                            if (type && fileTypes[type]) {
                                _CodeEditor.icon = <SvgIcon iconClass={fileTypes[type]} fontSize='14px'></SvgIcon>
                            } else {
                                _CodeEditor.icon = <SvgIcon iconClass={'itab-default'} fontSize='14px'></SvgIcon>
                            }
                        }
                        _CodeEditor.props = {
                            ..._CodeEditor.props,

                            // value.url 区分是课程资源还是我的资源
                            wsUrl: value?.url ? `${config.wsURL}?key=${user}&repo=${repo}&_private=true&path=${encodeURIComponent(value.url || '')}&component=file&workdir=1&file=${encodeURIComponent(value?.path || '')}` : _CodeEditor.props.wsUrl + encodeURIComponent(value?.path || ''),
                            language: value?.path === 'index.md' ? 'md' : getFileSuffix(value?.title)
                        }
                        _component = _CodeEditor
                    }
                    break;
                case "Markdown":
                    if (!isComponentExist(_Markdown.id)) {
                        _component = _Markdown
                    }
                    break;
                case "Xterm":
                    {
                        if (value?.is) return
                        // 启动环境成功后打开shell：若shell已存在，不再重新开启
                        if (value?.from === 'startup') {
                            const xtermRefsKeys = Object.keys(xtermRefs)
                            if (xtermRefsKeys?.length) return
                        }
                        const id = uuid()
                        _component = _Xterm
                        _component.id = '_XtermShell_' + id
                        _component.props.id = id
                        _component.menu = podContainers?.length > 1 ? podContainers?.map((v: any) => {
                            return {
                                title: 'Shell:' + v?.container,
                                callback: () => {
                                    setPodContainer({
                                        ...v,
                                        shellId: _component.id
                                    })
                                }
                            }
                        }) : []
                        _component.menu?.push({
                            title: '新开Shell',
                            callback: () => {
                                window.postMessage({
                                    type: 'pushContainer',
                                    component: 'Xterm'
                                })
                            }
                        })
                        if (podContainers?.length) {
                            const index = podContainers.findIndex((i: any) => i.container === value.defaultShell)
                            // shell连接：默认选择第一个container进行连接
                            setPodContainer({
                                ...podContainers[index > 0 ? index : 0],
                                shellId: _component.id
                            })
                        }
                        const _ref = React.createRef<HTMLDivElement>()
                        _component.props.ref = _ref
                        setXtermRefs({
                            ...xtermRefs,
                            [_component.id]: _ref
                        })

                        if (value?.projectRun) {

                            setXtermSh(value?.projectRun)
                            // console.log(_component.props.conn);
                        } else {
                            setXtermSh('')
                        }

                    }
                    break;
                case "Webview":
                    {
                        let id = _Webview.id + value.path
                        // 区分是通过工具打开还是资源管理中的文件运行打开
                        if (value?.type === 'file') {
                            id = 'file' + id
                        }
                        const _value = value?.value
                        // codeplayer运行工具，每次执行都新建一个webview
                        if (!isComponentExist(id) || value?.name === 'CodePlayer') {
                            _Webview.id = value?.name === 'CodePlayer' ? _Webview.id + uuid() : id
                            _Webview.title = value?.name ?? '网页'
                            if (value?.customIcon === 'codeplayer') {
                                _Webview.icon = <SvgIcon iconClass={'codeplayer'} fontSize='14px'></SvgIcon>
                            } else if (value?.icon) {
                                _Webview.icon = value.icon
                            }

                            _Webview.props.pod = value?.pod
                            _Webview.props.url = value?.path
                            if (value?.name === 'CodePlayer' && _value) {
                                // codeplayer运行工具
                                _Webview.props.onLoadIframe = (_iframe: any) => {
                                    postRobot.send(_iframe?.current?.contentWindow, "send", {
                                        code: _value?.code,
                                        lang: _value?.lang,
                                        run: _value?.run ?? true,
                                        nameSpace: _value?.needPod ? config.nameSpace : undefined,
                                        podName: _value?.needPod ? pod : undefined,
                                        workspace: _value?.needPod ? `/home/community/${section?.path}` : undefined,
                                        aiEnabled: getAiEnabled('run', 'codePlayerTool'),
                                    })
                                }
                            }
                            // 在浏览器打开：打开新标签页，并关闭该webview
                            _Webview.props.onCloseTab = (popup: any) => {
                                if (value?.name === 'CodePlayer') {
                                    // if (!_value?.code) {
                                    //     // 读取演示脚本代码
                                    //     read({
                                    //         repo,
                                    //         path: section?.path,
                                    //         workdir: true,
                                    //         file: _value?.codePath
                                    //     }).then(res => {
                                    //         const data = res.data
                                    //         const _data = base64ToText(data)
                                    //         const _timer = setTimeout(() => {
                                    //             popup?.postMessage({
                                    //                 code: _data,
                                    //                 lang: _value?.codeLanguage,
                                    //                 run: true
                                    //             }, config?.codePlayerURL || '*')
                                    //             setCloseWebviewTab(_Webview.id)
                                    //             clearTimeout(_timer)
                                    //         }, 1000);
                                    //     }).catch(() => { })
                                    // } else {
                                    const _timer = setTimeout(() => {
                                        _value && postRobot.send(popup, "send", {
                                            code: _value?.code,
                                            lang: _value?.lang,
                                            run: _value?.run ?? true,
                                            nameSpace: _value?.needPod ? config.nameSpace : undefined,
                                            podName: _value?.needPod ? pod : undefined,
                                            workspace: _value?.needPod ? `/home/community/${section?.path}` : undefined,
                                            aiEnabled: getAiEnabled('run', 'codePlayerTool'),
                                        })
                                        // popup?.postMessage({
                                        //     code: _value?.code,
                                        //     lang: _value?.lang,
                                        //     run: _value?.run ?? true,
                                        //     nameSpace: _value?.needPod ? config.nameSpace : undefined,
                                        //     podName: _value?.needPod ? pod : undefined,
                                        //     workspace: _value?.needPod ? `/home/community/${section?.path}` : undefined
                                        // }, config?.codePlayerURL || '*')
                                        setCloseWebviewTab(_Webview.id)
                                        clearTimeout(_timer)
                                    }, 1000);
                                    // }
                                } else {
                                    setCloseWebviewTab(_Webview.id)
                                }
                            }
                            _component = _Webview
                        }
                    }
                    break;
                case "VideoPlayer":
                    if (!isComponentExist(_VideoPlayer.id)) {
                        _component = _VideoPlayer
                    }
                    break;
                // case "GPT":
                //     {
                //         if (!isComponentExist(_GPT.id)) {
                //             _component = _GPT
                //         }
                //     }
                //     break;
                case "PDFReader":
                    if (!isComponentExist(value?.path)) {
                        _PDFReader.id = value?.path
                        _PDFReader.title = value?.path
                        _PDFReader.props.url = value.url ? `${config.baseURL}/fs/content?repo=${repo}&_private=true&path=${encodeURIComponent(value.url)}&workdir=true&file=${encodeURIComponent(value?.path)}` : _PDFReader?.props?.url + encodeURIComponent(value?.path)
                        _component = _PDFReader
                    }
                    break;
                case "ExamPaper":
                    if (!isComponentExist(_ExamPaper.id)) {
                        _component = _ExamPaper
                    }
                    break;
                default:
                    break;
            }
            handleContainerPlace(_component)
        }
    }

    // 推送到哪个容器
    const handleContainerPlace = (_component: any) => {
        if (!_component) return
        const len = containers?.length
        switch (len) {
            case 0:
                {
                    const obj = {
                        current: _component.id,
                        components: [_component]
                    }
                    setContainers([obj])
                }
                break;
            case 1:
                if ((_component.component === LibroEditor || _component.component === VideoPlayer || _component.component === Markdown || _component.component === Editor || _component.component === PDFReader) && modal === '0') {
                    const obj = {
                        current: _component.id,
                        components: [_component]
                    }
                    setContainers([obj, ...containers])
                } else {
                    const obj = {
                        current: _component.id,
                        components: [_component]
                    }
                    setContainers([...containers, obj])
                }
                break;
            case 2:
                if (_component.component === LibroEditor || _component.component === VideoPlayer || _component.component === Markdown || _component.component === Editor || _component.component === PDFReader) {
                    containers[0].current = _component.id
                    containers[0].components.push(_component)
                } else {
                    containers[1].current = _component.id
                    containers[1].components.push(_component)
                }
                setContainers([...containers])
                break;
            default:
                break;
        }
    }

    // 判断当前打开的组件在容器中是否存在，存在则直接显示
    const isComponentExist = (id: any) => {
        const len = containers?.length
        if (!len) return false
        let exist = false
        for (const iterator of containers) {
            for (const item of iterator?.components) {
                if (item?.id === id) {
                    iterator.current = id
                    exist = true
                }
            }
        }
        exist && setContainers([...containers])
        return exist
    }

    // 关闭网页id
    useEffect(() => {
        if (closeWebviewTab) {
            closeTab(closeWebviewTab)
            setCloseWebviewTab('')
        }
    }, [closeWebviewTab])

    useEffect(() => {
        if (containers.length) {
            const el = document.getElementsByClassName('layout-container-content-container')[0]
            setContainerW(el?.clientWidth)
        } else {
            setLastSectionPath({})
        }
    }, [containers.length])

    useEffect(() => {
        window.addEventListener('message', handleContainer)
        return () => {
            window.removeEventListener('message', handleContainer)
        }
    }, [containers, xtermWSURL, podContainers, xtermRefs])

    // 全局loading状态
    useEffect(() => {
        const handle = (e: MessageEvent) => {
            const data = e.data
            const { type } = data
            if (type === 'closeLoading') {
                setEnterBeforeLoading(false)
            }
        }
        window.addEventListener('message', handle)
        return () => {
            window.removeEventListener('message', handle)
        }
    }, [])

    //获取考试详情
    const [examDetail, setExamDetail] = useState<any>()

    // 请求考试详情
    const getExamDetail = async () => {
        // 题目编码不存在直接返回
        if (!questionCode) return

        let res: any = null
        if (examRecordCode) {    // 考试中
            res = await examQuestionDetail({
                questionCode, examRecordCode
            }).catch(() => { })
        } else if (examCode) {   // 创建考卷中
            res = await examQuestionInfo({
                questionCode
            }).catch(() => { })
        } else {                 // 创建题库中  没有其他code
            res = await bankQuestionInfo({
                questionCode
            }).catch(() => { })
        }
        if (res) {
            setExamDetail(res)
        }
    }

    // 重新获取考试详情
    const addExamDetail = (e: any) => {
        const data = e.data
        if (data?.type === "reGetExamDetail") {
            getExamDetail()
        }
    }
    // 获取考试详情
    useEffect(() => {
        if (questionCode) {
            getExamDetail()
            window.addEventListener('message', addExamDetail)
            return () => {
                window.removeEventListener('message', addExamDetail)
            }
        }
    }, [questionCode, examRecordCode])

    // 容器关闭标签
    const closeTab = (id: string, index?: number) => {
        // 判断是否存在该组件，不存在则不做后续处理
        let exist = false
        for (let i = 0; i < containers.length; i++) {
            const element = containers[i];
            const arr = element?.components?.filter((v: any) => v?.id === id)
            if (arr?.length) {
                exist = true
                break
            }
        }
        if (!exist) return
        if (examType === '0' || courseType === courseTypes.BLOG || courseType === courseTypes.EXPERIENCE) {
            const count = containers?.reduce((total: any, item: any) => {
                return total + (item?.components?.length ?? 0)
            }, 0)
            if (count <= 1) {
                message.destroy()
                message.info('仅有一个标签页时，无法关闭')
                return
            }
        }
        if (id === _Markdown.id) {
            setPreview(false)
        }
        containers?.map((v: any) => {
            v.components = v?.components?.filter((v: any) => v?.id !== id)
            if (v?.current === id && v.components?.length) {
                v.current = v.components[v.components?.length - 1].id
            }
            return v
        })
        const _containers = containers?.filter((item: any) => item.components.length)
        // 清空选中小节
        if (!_containers.length) {
            setSection({})
            setCurrentChapter(undefined)
        }
        setContainers([..._containers])
        // 若关闭的是xterm，删除该xterm的ref
        const keys = Object.keys(xtermRefs)
        if (keys.includes(id)) {
            delete xtermRefs[id]
            setXtermRefs(xtermRefs)
        }
    }

    // 容器关闭其他标签页
    const closeOtherTabs = (index: number) => {
        containers[index].components = containers[index]?.components?.filter((item: any) => {
            return item?.id === containers[index].current
        })
        setContainers([...containers])
    }

    // 容器选择标签页
    const changeCurrent = (id: string, index: number) => {
        containers[index].current = id
        setContainers([...containers])
    }

    useEffect(() => {
        fetchCourse()
    }, [])

    // 寻找下一节（文本型课程学习完成，弹框）
    const lookNextSection: any = (arr: any, path: string) => {
        const lastLevel = arr?.filter((v: any) => !v?.isFolder);
        const len = lastLevel.length
        if (len) {
            let _index = 0
            lastLevel.forEach((element: any, index: number) => {
                if (element?.path === path) {
                    _index = index
                }
            });
            if (_index !== len - 1) {
                return lastLevel[_index + 1]
            }
        }
        return undefined
    }

    useEffect(() => {
        if (submitScore?.id) {
            // 提交，学习
            saveActive({
                activityId: submitScore?.id,
                chapterId: section?.describe?.chapterId ?? CryptoJS.MD5(repo + section?.path).toString(),
                number: activeId,
                repo: repo,
                scope: submitScore?.score,
                topic: submitScore?.id,
                topicConfig: submitScore && JSON.stringify(submitScore),
                topicResult: submitScore?.topicResult ?? '',
                topicStatus: submitScore?.topicStatus ?? '',
                userId: userId
            }).then(() => {
                setSubmitScore(undefined)
            })
        }
    }, [submitScore])

    useEffect(() => {
        if (finish && examType !== '0') {
            if (activitiesConfig?.mark) {
                setScoreShow(true)
            } else {
                successShowConfirm()
            }
        }
    }, [finish])

    useEffect(() => {
        if (section?.path) {
            console.log('当前小节信息 =》', section);
            // console.log("🚀lastSectionPath:", lastSectionPath)
            if (section?.path !== lastSectionPath?.path || section?.type !== lastSectionPath?.type || section?.editMode !== lastSectionPath?.editMode) {
                currentChapterPath.current = section?.path
                currentChapterId.current = section?.describe?.chapterId ?? CryptoJS.MD5(repo + section?.path).toString()
                handleSection()
                setLastSectionPath(section)
            }
            setActiveId(uuid())
        } else {
            console.log('小节清空');

            setContainers([])
        }
    }, [section])

    useEffect(() => {
        let template = section?.template
        if ((section?.type === 'text' || section?.type === 'video') && template?.experEnv && !template?.name && envs?.length) {
            const obj = envs?.find((v: any) => v?.location === template?.experEnv)
            template.name = obj?.name
        }
        window.postMessage({
            type: 'sectionChange',
            value: {
                template: template || ''
            }
        })
    }, [section, envs])

    useEffect(() => {
        if (section?.path) {
            fetchRead({
                repo,
                user,
                path: section?.path,
                sign: ''
            })
            // 设置小节id
            setChapterId(section?.describe?.chapterId ?? CryptoJS.MD5(repo + section?.path).toString())
        }
    }, [section?.path])

    useEffect(() => {
        // 容器更换 => xterm的shell的websocket地址更换、视频播放器、文本播放器里的“校验”功能的websocket地址更换
        if (pod) {
            const cwd = encodeURIComponent(section?.path)
            const examUrl = `${config.ojWSURL}/api/pod/exec?podName=${pod}&container=${podContainer?.container || ''}&cwd=${cwd}&script=${xtermSh}`
            const url = `${config.ptyWSURL}/api/terminal?pod=${pod}&container=${podContainer?.container || ''}&cwd=${cwd}`
            if (podContainer?.container) {
                xtermRefs[podContainer?.shellId]?.current?.changeConn(projectType.MARK === _projectType ? examUrl : xtermSh ? url + `&script=${xtermSh}` : url)
                xtermRefs[podContainer?.shellId]?.current?.changeShellType(xtermSh ? true : false)
            } else {
                const keys = Object.keys(xtermRefs)
                keys?.length && xtermRefs[keys[keys?.length - 1]]?.current?.changeConn(projectType.MARK === _projectType ? examUrl : xtermSh ? url + `&script=${xtermSh}` : url)
                keys?.length && xtermRefs[keys[keys?.length - 1]]?.current?.changeShellType(xtermSh ? true : false)
            }
            textMarkdowmRef?.current?.changeUrl(url)
            editorRef?.current?.changeXternUrl(url)
            setXtermWSURL(url)
        } else {
            setXtermWSURL('')
        }
    }, [pod, podContainer, xtermRefs, section?.path])

    useEffect(() => {
        editorRef?.current?.changeXternUrl(xtermWSURL)
    }, [xtermWSURL])

    // 切换shell组件容器：更换shell的标题为容器的名称
    useEffect(() => {
        if (pod && podContainer?.container && containers?.length) {
            for (const iterator of containers) {
                const index = iterator?.components?.findIndex((v: any) => v?.id === podContainer?.shellId)
                if (index > -1) {
                    const title = 'Shell:' + podContainer?.container
                    iterator.components[index].title = title
                    iterator.components[index].menu = iterator?.components[index]?.menu?.map((v: any) => {
                        if (v?.title === title) {
                            v.checked = true
                        } else {
                            v.checked = false
                        }
                        return v
                    })
                    break
                }
            }
            setContainers([...containers])
        }
    }, [pod, podContainer])

    /** EDIT BY WZX START */
    // 好像没有用到
    // const resourcemanager = useRef<any>()

    const resourceDir = useRef<any>()

    const [params, setParams] = useState<any>()

    const [courseUrl, setCourseUrl] = useState<any>()
    const [chapterUrl, setChapterUrl] = useState<any>()

    //学习中的我的资源
    const [myResourceUrl, setMyResourceUrl] = useState<any>()
    // 往上移动一下，视频组件需要用到这个参数
    // const [selectedChapter, setSelectedChapter] = useState<any>()
    const [selectedFile, setSelectedFile] = useState<any>()

    const [courseInfo, setCourseInfo] = useState<any>()
    const [fileInfo, setFileInfo] = useState<any>()
    const [speed, setSpeed] = useState<any>()

    const [currentChapter, setCurrentChapter] = useState<any>()

    const [morePostion, setMorePostion] = useState<any>()
    const [moreMenus, setMoreMenus] = useState<any>()

    const [showCourseModal, setShowCourseModal] = useState<boolean>(false)
    const [isUpdateCourse, setIsUpdateCourse] = useState<boolean>(false)
    const [courseModalForm, setCourseModalForm] = useState<any>()

    const [topType, setTopType] = useState<string>()
    const [labDetail, setLabDetail] = useState({})    // 实验室关于github的信息

    // 当前最外层的环境信息
    const [repoEnvStatus, setRepoEnvStatus] = useState<any>()

    // 侧边栏相关状态展开控制
    const [siderOpen, setSiderOpen] = useState({
        // 课程目录是否展开
        courseDirectory: true,
        // 资源管理
        resourceManage: true,
        // 工具
        tools: true
    })

    // 改变侧边栏展开和收起
    const changeSiderOpen = (key: keyof typeof siderOpen, value: boolean = true, only: boolean = false) => {
        // 当且仅打开一个工具
        if (only && value) {
            const res: any = {}
            for (const key in siderOpen) {
                res[key] = false
            }
            res[key] = value
            setSiderOpen(res)
            return
        }
        const res = { ...siderOpen }
        res[key] = value
        setSiderOpen(res)
    }

    // 获取实验室关于github绑定的相关信息
    useEffect(() => {
        if (labCode) {
            getLabToken({ labCode, type: 'github' }).then((res) => {
                setLabDetail(res)
            }).catch(() => { })
            // 获取环境列表
            getExperEnv({ owner: userId, lab: labCode }).then(res => {
                setEnvs(res)
            }).catch(() => { })
        }
    }, [labCode])

    // 侧边栏相关开关的控制, 是否当前有选中小节才能进行完全的打开控制，否则只能是关闭状态
    useEffect(() => {
        if (!selectedChapter?.describe?.type || !section?.path) {
            setSiderOpen({ ...siderOpen, resourceManage: false, tools: false })
        } else {
            setSiderOpen({ ...siderOpen, resourceManage: true, tools: true })
        }
    }, [selectedChapter?.describe?.type, section?.path])

    useEffect(() => {
        setSelectedFile(undefined)
    }, [chapterUrl])

    useEffect(() => {
        const fetchSetSection = async () => {
            if (selectedChapter) {
                const props = {
                    repo: params.get('repo'),
                    key: params.get('key'),
                    path: selectedChapter?.path,
                    workdir: true,
                    component: 'fs'
                }

                let template = null;
                try {
                    if (location) {
                        const split = location.split("/")
                        const res = await getTemplateById({ id: split[2], lab: split[1] })
                        const data = res.containers[0]?.ports?.filter((i: any) => i.choice)
                        template = {
                            duration: 30,
                            experEnv: location,
                            expertools: res.containers[0]?.name,
                            ports: data ?? [],
                            defaultShell: res.defaultShell,
                            defaultOpen: res.containers[0]?.ports?.find((i: any) => i.isDefaultTool)?.port,
                            defaultOpenName: res.containers[0]?.ports?.find((i: any) => i.isDefaultTool)?.name,
                            openTools: res?.openTools ?? true
                        }
                    }
                } catch (e) { }
                setChapterUrl(getWebscoketUrl(props))

                let res = {
                    openTools: true
                }
                try {
                    if (selectedChapter?.describe?.template?.experEnv) {
                        const split = selectedChapter?.describe?.template?.experEnv.split("/")
                        if (split.length === 4) {
                            res = await getTemplateById({ id: split[2], lab: split[1] })

                        }
                    } else if (selectedChapter?.template?.experEnv) {
                        const split = selectedChapter?.template?.experEnv.split("/")
                        if (split.length === 4) {
                            res = await getTemplateById({ id: split[2], lab: split[1] })
                        }
                    }
                } catch (e) {
                    res = {
                        openTools: true
                    }
                }
                const _section = {
                    path: selectedChapter?.path,
                    title: selectedChapter?.title,
                    type: selectedChapter?.describe?.type,
                    template: template ??
                    {
                        ...selectedChapter?.template,
                        ports: selectedChapter?.template?.ports?.filter((i: any) => i.choice) ?? [],
                        defaultOpen: selectedChapter?.describe?.template?.defaultOpen ?? selectedChapter?.template?.ports?.find((i: any) => i.isDefaultTool)?.port,
                        defaultOpenName: selectedChapter?.describe?.template?.defaultOpenName ?? selectedChapter?.template?.ports?.find((i: any) => i.isDefaultTool)?.name,
                        openTools: res?.openTools ?? true
                    },
                    describe: selectedChapter?.describe,
                    env: selectedChapter?.env,
                    editMode: (selectedChapter?.describe?.editMode == undefined || selectedChapter?.describe?.editMode === 'new') ? 'new' : ''
                }
                setSection(_section)
                // setXtermAction(null)
            } else {
                setSection({})
            }
        }
        fetchSetSection();
    }, [selectedChapter])

    useEffect(() => {
        // 同步状态
        console.log('当前选中 =>', currentChapter)
        if (currentChapter && 'content' === currentChapter?.describe?.category) {
            setRepoStatus({
                repo: params.get('repo'),
                key: params.get('key'),
                path: currentChapter?.path ?? ''
            })
        }
    }, [currentChapter?.path])

    useEffect(() => {
        if (selectedFile) {
            // 打开编辑器
            let msg: any = {}
            if (verifyIsImage(selectedFile?.path)) {
                msg = {
                    type: 'pushContainer',
                    component: 'Webview',
                    value: {
                        path: `${config.baseURL}/fss/resource/${repo}/${encodeURIComponent(section?.path ?? '')}/workdir/${encodeURIComponent(selectedFile?.path ?? '')}`,
                        name: selectedFile?.path ?? '图片'
                    }
                }
            } else if (verifyIsPackage(selectedFile?.path)) {
                message.destroy()
                message.warning('暂不支持打开压缩包文件，请下载后再查看')
                return
            } else {
                msg = {
                    type: 'pushContainer',
                    component: selectedFile?.path?.endsWith('.pdf') ? 'PDFReader' : selectedFile?.path?.endsWith('.ipynb') ? 'LibroEditor' : 'CodeEditor',
                    value: selectedFile
                }
            }
            // console.log(msg, containers);

            window.postMessage(JSON.parse(JSON.stringify(msg)))
        }
    }, [selectedFile])

    useEffect(() => {
        params && setCourseUrl(getWebscoketUrl({
            repo: params?.get('repo'),
            key: userId
        }))
    }, [params])

    useEffect(() => {
        if (courseInfo) {
            const _selectedChapter = courseInfo?.find((it: any) => it?.path === selectedChapter?.path)
            if (_selectedChapter) {
                console.log('设置选中 =>', speed, _selectedChapter)
                setSelectedChapter(_selectedChapter)
                setCurrentChapter(_selectedChapter)
            } else {
                setSelectedChapter(undefined)
                setSelectedFile(undefined)
                setChapterUrl(undefined)
            }
        }
        if (courseInfo && speed) {
            const filter = courseInfo?.filter((it: any) => it?.path === speed)
            if (filter && filter?.length > 0) {
                console.log('设置进度 =>', speed, filter[0])
                setSpeed('')
                setCurrentChapter(filter[0])
                setSelectedChapter(filter[0])
            } else {
                setLoadingSpeed(false)
            }
        }
        if (courseInfo && courseInfo?.length > 0) {
            setTopType(courseInfo[0]?.describe?.type || 'text')
        } else {
            setTopType('')
        }
    }, [courseInfo, speed])

    useEffect(() => {
        if (fileInfo && fileInfo?.filter((it: any) => it?.path === selectedFile?.path)?.length === 0) {
            setSelectedFile(undefined)
        }
    }, [fileInfo])

    useEffect(() => {
        hljs.initHighlightingOnLoad();
        const _params = new URLSearchParams(window.location.search)
        if (_params)
            setParams(_params)
    }, [])
    /** EDIT BY WZX END */


    // 最开始的接口 初始化
    const initRepo = () => {
        return getRepoStatus({
            repo,
            key: user
        }).then(res => {
            //是否考试
            if (examLabCode) {
                setLabCode(examLabCode)
                setCourseCode(examLabCode)
            } else {
                fetchLabCode(res)
                setCourseCode(res?.code)
            }

            // 无token的情况下，旧数据无返回courseType（太乙）
            if (res?.courseType && !params?.get('token')) {
                const type = res?.courseType?.toUpperCase()
                setCourseType(type)
                if (type === courseTypes.EXPERIENCE) {
                    setEnterBeforeLoading(false)
                }
                if (type === courseTypes.EXPERIENCE) {
                    // 快速体验课程，左侧边栏默认收起
                    setFold(true)
                }
                if (type === courseTypes.BLOG || type === courseTypes.EXPERIENCE) {
                    // 博客、体验课程直接设置课程目录数据，匹配section数据
                    setCourseInfo(res?.stat || [])
                    if (!res?.status && res?.stat?.length) {
                        // 若无当前节，则设置第一节为当前节
                        setSpeed(res?.stat[0]?.path)
                    }
                }
            }

            setRepoEnvStatus(res)
            if (modal !== '1') {
                // 是否更新
                setIsUpdate(res?.isUpdate)
                if (res?.isUpdate) {
                    Modal.confirm({
                        title: "更新课程",
                        content: <div>
                            <p style={{ margin: '0' }}>1、课程内容有变更，点击【更新】即可更新至线上最新课程内容。</p>
                            <p style={{ margin: '0' }}>2、请放心，更新后，课程学习进度及考试状态不会丢失噢~</p>
                        </div>,
                        cancelText: '我再想想',
                        okText: '确认更新',
                        centered: true,
                        width: 400,
                        onOk() {
                            window.postMessage({
                                type: 'reGitPull'
                            })
                        }
                    })
                }
            }
            // 当前状态
            const _speed = chapter || res?.status
            console.log('speed path ->', _speed)
            if (_speed) {
                setSpeed(_speed)
            } else {
                setLoadingSpeed(false)
            }
            if (examType === '0') {
                // 考试直接设置课程目录数据，匹配section数据
                setCourseInfo(res?.stat || [])
            }
        })
    }

    // 报错重新请求两次
    useEffect(() => {
        initRepo().catch(() => {
            setTimeout(() => {
                initRepo().catch(() => { })
            }, 1000)
        })
    }, [])

    // 获取实验室code、课程详情
    const fetchLabCode = (data: any) => {
        getLabCode({ code: data?.code }).then(res => {
            setLabCode(res?.labCode)
            const type = res?.type
            setCourseType(type)
            if (type === courseTypes.EXPERIENCE) {
                setEnterBeforeLoading(false)
            }
            setCourseTitle(res?.name || '')
            if (examType === '0' || type === courseTypes.BLOG || type === courseTypes.EXPERIENCE) {
                const lady = () => {
                    if (editorRef?.current) {
                        editorRef?.current?.setTitle(res?.name === '未命名' ? '' : (res?.name || ''))
                    } else {
                        setTimeout(() => {
                            lady()
                        }, 1000);
                    }
                }
                lady()
            }
            document.title = res?.name || '开放平台'
            if (type === courseTypes.EXPERIENCE) {
                // 快速体验课程，左侧边栏默认收起
                setFold(true)
            }
            if (type === courseTypes.BLOG || type === courseTypes.EXPERIENCE) {
                // 博客、体验课程直接设置课程目录数据，匹配section数据
                setCourseInfo(data?.stat)
                if (!data?.status && data?.stat?.length) {
                    // 若无当前节，则设置第一节为当前节
                    setSpeed(data?.stat[0]?.path)
                }
            }
        })
    }

    //通过md编辑器触发小节编辑框编辑课程小节
    const editPath = () => {
        setMorePostion(undefined)
        setIsUpdateCourse(true)
        setShowCourseModal(true)
        setCourseModalForm(currentChapter)
    }

    //是否有文件处于加载中
    const [ifUpload, setIfUpload] = useState(false)

    useEffect(() => {
        window.localStorage.setItem('activeUrl', `${config.baseURL}/fs/read?repo=${repo}&path=${encodeURIComponent(section?.path || '')}&sign=''`)
        //提供给md
        window.localStorage.setItem('modal', modal || '')
        if (section?.template?.experEnv) {
            window.localStorage.setItem('template', 'true')
        } else {
            window.localStorage.setItem('template', 'false')
        }
    }, [section?.path])

    useEffect(() => {
        if (modal === '1' || !courseInfo || !section?.path) return
        // 学习 => 寻找下一节
        const _nextSection = lookNextSection(courseInfo, section?.path)
        setNextSection(_nextSection)
        console.log('下一节 => ', _nextSection);
    }, [courseInfo, section?.path])

    useEffect(() => {
        const handleClick = (event: any) => {
            // 处理点击事件  清除菜单
            const menu = document.getElementById('more-menu-id') as HTMLDivElement
            if (!menu?.contains(event.target)) {
                setMorePostion(undefined)
            }
        };
        if (morePostion) {
            document.addEventListener('click', handleClick);
            return () => {
                document?.removeEventListener('click', handleClick);
            };
        }

    }, [morePostion]);



    //监听浏览器关闭事件  是否有文件处于上传状态
    useEffect(() => {

        const close = (event: any) => {
            if (!ifUpload) return

            event?.preventDefault();

        }
        window.addEventListener('beforeunload', close);
        return () => {
            window.removeEventListener('beforeunload', close)
        }
    }, [ifUpload])

    // 考试场景下调用
    useEffect(() => {

        if (labCode && couseCode && examType !== '0') {
            getUserd({
                repo: params.get('repo'),
            }).then(res => {
                const props = {
                    repo: params.get('repo'),
                    key: params.get('key'),
                    path: `${labCode}/${couseCode}/${res.userId}`,
                    workdir: true,
                    component: 'fs'
                }
                // console.log(props.path);
                // console.log(res)

                setMyResourceUrl(`${config?.wsURL}?key=${props?.key ?? ''}&repo=${props?.repo ?? ''}&_private=true&path=${props?.path ?? ''}&component=${props?.component ?? ''}&workdir=true`)
            })
        }
    }, [labCode, couseCode])

    // 点击刷新的控制  图标旋转控制
    const [fresh, setFresh] = useState(false)
    // 刷新课程目录   loading 刷新是否有lodding  默认没有  因为有些是背后默认刷新的
    const refresh = useCallback(async (loading: boolean = false) => {
        if (fresh) return
        if (loading) {
            setFresh(true)
        }
        const res = await refreshCourse({ repo }).catch().finally(() => {
            setTimeout(() => {
                setFresh(false)
            }, 500);
        })
    }, [fresh, repo])

    // 项目型实操题考试禁用复制
    useEffect(() => {
        if (examType === '0' && type === Type.PROJECT && _projectType === projectType.EXAM && examDetail?.examType === 'POINT') {
            disable()
        }
    }, [examDetail?.examType])

    // 考试相关的数据 
    // examType === '0' 是否为考试类型, 
    // examstatus  实操题的相关类型 exam mark view, 
    // type 工程项目类  project, 
    // _projectType 子类型
    // disableCopyPaste是否禁用复制粘贴
    const examData = {
        examType,
        examstatus,
        type,
        _projectType,
        disableCopyPaste: (examType === '0' && type === Type.PROJECT && _projectType === projectType.EXAM && examDetail?.examType === 'POINT')
    }


    return (
        <ManaComponents.Application
            modules={[ManaAppPreset, LibroJupyterModule, CloudLabLibroModule]}
            renderChildren
        >
            <SharedDataContext.Provider value={{
                mdEditorRef, editorRef, params, selectedChapter, chapterUrl, setSelectedFile, setFileInfo, setCloneSuccess, globalEnvPod, globalEnvStatus, section, setSection, couseCode, labCode, userInfo, labDetail, courseInfo, repoEnvStatus, courseType, examType, type, _projectType, examData, xtermWSURL, editPath, chapterId, codePlayerConnect
            }}>
                <div className='layout' >
                    {/* 顶部栏 */}
                    <Header
                        validateUrl={xtermWSURL}
                        examDetail={examDetail}
                        section={section}
                        labCode={labCode}
                        courseCode={couseCode}
                        modal={modal}
                        examType={examType ?? ''}
                        isUpdate={isUpdate}
                        isPreview={isPreview}
                        courseName={courseTitle}
                        courseType={courseType}
                        reqParams={{ repo, user }}
                        ifUpload={ifUpload}
                        containers={containers}
                        onStatus={(status: any) => {
                            setGlobalEnvStatus(status)
                            libroEditorRef?.current?.updateGlobalEnvStatus(status);
                            if (modal === '0' && status === 1) {
                                // 学习 => 课程、博客、内容：环境启动成功后 => 关闭实验环境启动组件
                                closeTab('_EnvStart_')
                            }
                        }}
                        onRunningPod={(value?: any, closeShell?: boolean) => {
                            // 当前小节运行的容器信息
                            setPodContainers(value?.containers || [])
                            setPod(value?.pod || '')
                            setGlobalEnvPod(value)
                            // 无运行环境时，shell组件需要关闭, 端口工具也需要关闭
                            if (!value?.pod && closeShell) {
                                containers?.map((v: any) => {
                                    v.components = v?.components?.filter((v: any) => !(v?.id?.startsWith('_XtermShell_') || v?.id?.startsWith('_Webview_')))
                                    const components = v.components
                                    const ids = components?.map((v: any) => v?.id)
                                    if (!ids?.includes(v?.current) && ids?.length) {
                                        v.current = ids[0]
                                    }
                                    return v
                                })
                                const _containers = containers?.filter((v: any) => v?.components?.length)
                                setContainers([..._containers])
                                setXtermRefs({})
                            }
                        }}
                        onStep={(step: number) => {
                            envStartRef?.current?.updateStep(step)
                        }}
                        onCurrentPodInfo={(value: any) => {
                            envStartRef?.current?.updateData(value)
                        }}
                        onCourseNameChange={(value: string) => {
                            syncTitle('toEditor', value)
                            setCourseTitle(value)
                            document.title = value
                            // 博客/体验课程：修改编辑器的标题
                            if (courseType === courseTypes.BLOG || courseType === courseTypes.EXPERIENCE) {
                                for (const iterator of containers) {
                                    const indexEditor = iterator?.components?.findIndex((v: any) => v?.id === '_Editor')
                                    if (indexEditor > -1) {
                                        iterator.components[indexEditor].title = value
                                    }
                                }
                                setContainers([...containers])
                            }
                        }}
                    />

                    <div className='layout-container'>
                        {/* 侧边栏  收起/展开 */}
                        {
                            fold ? <div className='layout-container-foldsidebar'>
                                <LeftSider showDirectory={showBlogDirectory} open={(key: keyof typeof siderOpen) => {
                                    setFold(false)
                                    setSidebarW(240)
                                    changeSiderOpen?.(key, true, true)
                                }} examType={examType} />
                            </div> : <div className='layout-container-sidebar' style={{ width: sidebarW + 'px' }}>
                                {
                                    examType !== '0' && courseInfo?.length < 1 && courseInfo && modal === '1' && siderOpen['courseDirectory'] && <div className='noInfo'>
                                        这里还没有任何内容，快去添加课程
                                        {/* <span style={{ color: '#1677FF', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                                        setMorePostion(undefined)
                                        setIsUpdateCourse(false)
                                        setShowCourseModal(true)
                                        setCourseModalForm({})
                                    }}>添加课程</span> */}
                                        吧~
                                    </div>
                                }
                                <Sider siderOpen={siderOpen}>
                                    {
                                        (courseType === courseTypes.BLOG || courseType === courseTypes.EXPERIENCE) && showBlogDirectory && (
                                            <div data-sider="courseDirectory">
                                                <BlogDirectory
                                                    courseType={courseType}
                                                    wsUrl={`${config.wsURL}?key=${user}&repo=${repo}&path=${encodeURIComponent(section?.path || '')}&component=file&file=index.md`}
                                                    open={siderOpen['courseDirectory']}
                                                    changeOpen={(value: boolean) => {
                                                        changeSiderOpen('courseDirectory', value)
                                                    }}
                                                    onDirectoryChange={(value?: any) => {
                                                        if (modal !== '1') {
                                                            // 学习模态下，博客目录无数据，不显示“博客目录”
                                                            setShowBlogDirectory(value?.length ? true : false)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        )
                                    }
                                    {
                                        examType !== '0' && courseType === courseTypes.COURSE && (
                                            <div data-sider="courseDirectory">
                                                <Tree
                                                    url={courseUrl}
                                                    title={'课程目录'}
                                                    treeType={'table'}
                                                    ref={resourceDir}
                                                    open={siderOpen['courseDirectory']}
                                                    changeOpen={(value: boolean) => {
                                                        changeSiderOpen('courseDirectory', value)
                                                    }}
                                                    // foldIcon={(<SvgIcon iconClass='right' ></SvgIcon>)}
                                                    // expandIcon={(<SvgIcon iconClass='down' ></SvgIcon>)}
                                                    moreIcon={(<SvgIcon iconClass='tree-more' fontSize='14px'></SvgIcon>)}
                                                    selected={currentChapter}
                                                    isDrag={modal === '1'}
                                                    modal={modal}
                                                    defaultFold={true}
                                                    backCourseList={(nodes: any) => {
                                                        //回传目录结构
                                                        setLoadingCourseData(false)
                                                        if (!nodes?.length) {
                                                            setLoadingSpeed(false)
                                                        }
                                                    }}
                                                    changeCourse={
                                                        (title: string, path: string, parent: string, describe: object) => {
                                                            return rename({
                                                                data: { title: title.trim(), path, parent, describe },
                                                                repo: params?.get('repo'),
                                                                key: params?.get('key'),
                                                                // path: selectedChapter?.path,
                                                            })
                                                        }
                                                    }
                                                    tip={(str: string) => {
                                                        message.warning(str)
                                                    }}
                                                    // over={() => {
                                                    //     setTimeout(() => {
                                                    //         setFresh(false)
                                                    //     }, 1000);
                                                    // }}
                                                    showMoreIcon={modal === '1' && !params?.get('preview')}
                                                    customIcons={modal === '1' && !params?.get('preview') && [
                                                        {
                                                            icon: courseInfo?.length > 0 && courseInfo ? <SvgIcon iconClass='tree-fresh' style={{ color: '#999999' }} className={fresh ? 'freshh-rotate' : ''}></SvgIcon> : <></>,
                                                            name: '刷新',
                                                            onClick: (data: any) => {
                                                                // setFresh(true)
                                                                // resourceDir?.current?.refresh()
                                                                refresh(true)
                                                            }
                                                        },
                                                        {
                                                            icon: <SvgIcon iconClass='tree-add'></SvgIcon>,
                                                            name: '添加',
                                                            onClick: (data: any) => {
                                                                setMorePostion(undefined)
                                                                setIsUpdateCourse(false)
                                                                setShowCourseModal(true)
                                                                setCourseModalForm({})
                                                            }
                                                        }
                                                    ]}
                                                    changedHandle={(data: any): any => {
                                                        // console.log('data => ', data);

                                                        setCourseInfo(data)
                                                        return data?.map((it: any) => {
                                                            if ('content' === it?.describe?.category) {
                                                                it.isFolder = false
                                                                it.foldIcon = 'video' === it?.describe?.type ? (<SvgIcon iconClass='tree-video' fontSize='14px' style={{ width: '14px', height: '15px' }}></SvgIcon>) : it?.describe?.type === 'exam' ? <SvgIcon iconClass='exam' fontSize='14px' style={{ width: '14px', height: '15px' }}></SvgIcon> : (it?.describe?.type ? (<SvgIcon iconClass='tree-text' fontSize='14px' style={{ width: '14px', height: '15px' }}></SvgIcon>) : (<SvgIcon iconClass='tree-file' fontSize='14px' style={{ width: '14px', height: '15px' }}></SvgIcon>))
                                                                it.expandIcon = it.foldIcon
                                                            } else {
                                                                it.foldIcon = (<SvgIcon iconClass='tree-bottom' fontSize='14px' style={{ transform: 'rotate(-90deg)' }}></SvgIcon>)
                                                                it.expandIcon = (<SvgIcon iconClass='tree-bottom' fontSize='14px' ></SvgIcon>)
                                                            }
                                                            return it
                                                        })
                                                    }}
                                                    onClick={(data: any) => {

                                                        setMorePostion(undefined)
                                                        setCurrentChapter(data)
                                                        if (!data) return
                                                        if (!data?.isFolder && data?.describe?.type) {

                                                            setSelectedChapter(data)
                                                            //区分是点击时 要不要将tab切换至markdown
                                                            if (data?.path === section?.path && data?.describe?.type !== 'video' && data?.describe?.type !== 'notebook' && data?.describe?.type !== 'exam') {
                                                                window.postMessage({
                                                                    type: 'pushContainer',
                                                                    component: (selectedChapter?.describe?.editMode == undefined || selectedChapter?.describe?.editMode === 'new') ? 'Editor' : modal === '1' ? 'MdEditor' : 'Markdown',
                                                                })
                                                            }
                                                        }
                                                        if (!data?.describe?.type) {
                                                            setIsUpdateCourse(true)
                                                            setShowCourseModal(true)
                                                            setCourseModalForm(data)
                                                        }
                                                    }}
                                                    onEntryMore={(data: any, event: any) => {
                                                        // console.log(data, event);
                                                        // setCurrentChapter(data)
                                                        setMorePostion({
                                                            top: event?.clientY - 15,
                                                            // left: event?.clientX + 20
                                                            left: sidebarW
                                                        })
                                                        setMoreMenus(data.isFolder ? [
                                                            {
                                                                title: '添加',
                                                                onClick: () => {
                                                                    setMorePostion(undefined)
                                                                    setIsUpdateCourse(false)
                                                                    setShowCourseModal(true)
                                                                    setCourseModalForm(data)
                                                                }
                                                            },
                                                            {
                                                                title: '编辑',
                                                                onClick: () => {
                                                                    setMorePostion(undefined)
                                                                    if (globalEnvStatus) {
                                                                        Modal.warning({
                                                                            title: '温馨提示',
                                                                            content: '环境未关闭，无法编辑小节，请先关闭环境~',
                                                                            centered: true,
                                                                            okText: '我知道了'
                                                                        })
                                                                        return
                                                                    }
                                                                    setIsUpdateCourse(true)
                                                                    setShowCourseModal(true)
                                                                    setCourseModalForm(data)
                                                                }
                                                            },
                                                            {
                                                                title: '删除',
                                                                onClick: () => {
                                                                    rm({
                                                                        repo: params?.get('repo'),
                                                                        key: params?.get('key'),
                                                                        data: data
                                                                    }).then(res => {
                                                                        setCurrentChapter(undefined)
                                                                        setMorePostion(undefined)
                                                                        // resourceDir?.current?.refresh()
                                                                        refresh(false)
                                                                    })
                                                                }
                                                            }
                                                        ] : [
                                                            {
                                                                title: '打开',
                                                                onClick: () => {
                                                                    setMorePostion(undefined)
                                                                    if (!data?.isFolder && data?.describe?.type) {
                                                                        setSelectedChapter(data)
                                                                    }
                                                                    if (!data?.describe?.type) {
                                                                        setIsUpdateCourse(true)
                                                                        setShowCourseModal(true)
                                                                        setCourseModalForm(data)
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                title: '编辑',
                                                                onClick: () => {
                                                                    setMorePostion(undefined)
                                                                    if (globalEnvStatus) {
                                                                        Modal.warning({
                                                                            title: '温馨提示',
                                                                            content: '环境未关闭，无法编辑小节，请先关闭环境~',
                                                                            centered: true,
                                                                            okText: '我知道了'
                                                                        })
                                                                        return
                                                                    }
                                                                    setIsUpdateCourse(true)
                                                                    setShowCourseModal(true)
                                                                    setCourseModalForm(data)
                                                                }
                                                            },
                                                            {
                                                                title: '删除',
                                                                onClick: () => {
                                                                    rm({
                                                                        repo: params?.get('repo'),
                                                                        key: params?.get('key'),
                                                                        data: data
                                                                    }).then(res => {
                                                                        setCurrentChapter(undefined)
                                                                        setMorePostion(undefined)
                                                                        // resourceDir?.current?.refresh()
                                                                        refresh(false)
                                                                    })
                                                                }
                                                            }
                                                        ])
                                                    }}
                                                // onSelected={(data: any) => setCurrentChapter(data)}
                                                />
                                            </div>
                                        )
                                    }

                                    {(
                                        <div data-sider="resourceManage">
                                            <ResourceManage exam={examType === '0' && type === Type.PROJECT} courseType={courseType} couseCode={couseCode} userId={userId} labCode={labCode} repo={repo} params={params} myResourceUrl={myResourceUrl} chapterUrl={chapterUrl} modal={modal || ''} changeGit={e => {
                                                setCloneSuccess(e)

                                            }} selectedChapter={selectedChapter} setMorePostion={e => {
                                                if (e?.left) {
                                                    e.left = sidebarW
                                                }
                                                setMorePostion(e)
                                            }} setMoreMenus={e => {
                                                setMoreMenus(e)
                                            }} setSelectedFile={e => {
                                                setSelectedFile(e)
                                            }} setFileInfo={e => {
                                                setFileInfo(e)
                                            }} upLoadFile={e => {
                                                setIfUpload(e)
                                            }}
                                                removeFile={e => {
                                                    if (containers?.length > 1) {
                                                        closeTab(e.path + e?.url, 1)
                                                    }

                                                }}
                                                open={siderOpen['resourceManage']}
                                                changeOpen={(value: boolean) => {
                                                    if (selectedChapter?.describe?.type && section?.path) {
                                                        changeSiderOpen('resourceManage', value)
                                                    } else {
                                                        message.destroy()
                                                        message.warning('请先打开一个小节!')
                                                    }
                                                }}
                                                projectType={type}
                                                examData={examData}
                                                section={section}
                                            ></ResourceManage>

                                        </div>
                                    )
                                    }
                                    {
                                        <div data-sider="tools">
                                            <Tools
                                                modal={modal}
                                                section={section}
                                                setSection={setSection}
                                                labCode={labCode}
                                                repo={repo}
                                                userId={userId}
                                                envPod={globalEnvPod}
                                                currentChapter={selectedChapter}
                                                setcurrentChapter={setSelectedChapter}
                                                open={siderOpen['tools']}
                                                changeOpen={(value: boolean) => {
                                                    if (selectedChapter?.describe?.type && section?.path) {
                                                        changeSiderOpen('tools', value)
                                                    } else {
                                                        message.destroy()
                                                        message.warning('请先打开一个小节!')
                                                    }
                                                }}
                                                courseType={courseType}
                                                examType={examType}
                                                libroEditorRef={libroEditorRef}
                                            ></Tools>
                                        </div>
                                    }
                                </Sider>
                                {/** 更多菜单 */}
                                {
                                    moreMenus?.length > 0 && (modal === '1' && !params?.get('preview') ? <MoreMenu show={morePostion ? true : false}
                                        offset={morePostion}
                                        menus={moreMenus}

                                    /> : (moreMenus[0]?.title === '上传文件' || moreMenus[0]?.title === '编辑' || moreMenus[0]?.title === '运行') ? <MoreMenu show={morePostion ? true : false}
                                        offset={morePostion}
                                        menus={moreMenus}

                                    /> : <></>)
                                }

                                {/** 课程表单 */}
                                <CourseModal form={courseModalForm}
                                    courseType={courseType}
                                    courseInfoList={courseInfo}
                                    isUpdate={isUpdateCourse}
                                    open={showCourseModal}
                                    TopRepoEnvStatus={repoEnvStatus}
                                    labCode={labCode}
                                    topType={topType}
                                    userId={userId || ''}
                                    user={user || ''}
                                    creator={creator}
                                    onCancel={() => {
                                        setIsUpdateCourse(false)
                                        setShowCourseModal(false)
                                        setCourseModalForm(undefined)
                                    }}
                                    onOK={(data: any) => {
                                        if (data?.parent?.startsWith('/')) {
                                            data.parent = ''
                                        }
                                        !isUpdateCourse ? mkdir({
                                            repo: params?.get('repo'),
                                            key: params?.get('key'),
                                            data: {
                                                title: data?.title,
                                                path: data?.path,
                                                parent: data?.parent,
                                                describe: data?.describe ?? {},
                                                // template: data?.template ?? {}
                                            }
                                        }).then(res => {
                                            setShowCourseModal(false)
                                            setCourseModalForm(undefined)
                                            if (res?.describe.category === 'content') {
                                                setSpeed(res?.path)
                                            }
                                        }).catch(err => { message.destroy(); message.error(err?.message ?? '创建失败') })
                                            : rename({
                                                repo: params?.get('repo'),
                                                key: params?.get('key'),
                                                data: {
                                                    title: data?.title.trim(),
                                                    path: data?.path,
                                                    parent: data?.parent,
                                                    describe: data?.describe ?? {},
                                                    // template: data?.template ?? {}
                                                }
                                            }).then(res => {
                                                setShowCourseModal(false)
                                                setCourseModalForm(undefined)
                                                if (res?.describe.category === 'content') {
                                                    setSpeed(res?.path)
                                                }
                                                // 好像没有用到
                                                // resourcemanager?.current?.refresh()
                                            }).catch(err => {
                                                // console.log(err);
                                                message.destroy()
                                                message.error(err?.response?.data?.message ?? '重命名失败')
                                            })

                                    }}
                                />
                            </div>
                        }

                        {/* 侧边栏展开时拖拉分割线 */}
                        {
                            (examType === '0' || info?.status === 1) && !fold ? <Line state="col" min={220} move={(x: number) => {
                                setSidebarW(sidebarW + x)
                            }} minCallback={() => { setFold(true) }} /> : <></>
                        }

                        {/* 工作台核心展示区 */}
                        {
                            containers?.filter((v: any) => v?.components && v?.components.length).length === 0 ? (
                                <div className='layout-container-empty' >
                                    {
                                        !loadingCourseData && !loadingSpeed ?
                                            <>
                                                {
                                                    courseInfo?.length > 0 ? <div>
                                                        <SvgIcon iconClass={modal === '1' ? 'creatNovalue' : 'studyNovalue'} fontSize='180px' />
                                                        <span className='span'>{modal === '1' ? '请先打开小节才能进行内容创作~' : '您还没有打开任何内容~'}</span>
                                                    </div> : <div>
                                                        <SvgIcon iconClass={'studyNovalue'} fontSize='180px' />
                                                        <span className='span'>您还没有添加课程，快去添加试试吧~</span>
                                                        <Button type='primary' onClick={() => {
                                                            setMorePostion(undefined)
                                                            setIsUpdateCourse(false)
                                                            setShowCourseModal(true)
                                                            setCourseModalForm({})
                                                        }} style={{ minWidth: '100px', marginTop: '20px' }}>立即添加</Button>
                                                    </div>
                                                }
                                            </> : <></>
                                    }
                                </div>
                            ) : (
                                <div className='layout-container-content' >
                                    {
                                        containers?.filter((v: any) => v?.components && v?.components.length).map((v: any, index: number) => (
                                            <Fragment key={index}>
                                                <div className='layout-container-content-container'>
                                                    <Container tabs={v?.components} current={v?.current} closeTab={(id: string) => {
                                                        closeTab(id, index)
                                                    }} closeOtherTabs={() => {
                                                        closeOtherTabs(index)
                                                    }} changeCurrent={(id: string) => {
                                                        changeCurrent(id, index)
                                                    }} />
                                                    {
                                                        modal === '1' && section?.type === 'text' && (v.current === _MdEditor.id || v.current === _Editor.id) ? (
                                                            <div className='layout-container-content-container-topright'>
                                                                {
                                                                    v.current === _MdEditor.id ? <Button type='primary' ghost={preview} onClick={onPreview}>{!preview ? '预览' : '关闭预览'}</Button> : <></>
                                                                }
                                                                <Button type='primary' className='ant-green' onClick={onChangeEditor}>{section?.editMode === 'new' ? '切换旧编辑器' : '体验新编辑器'}</Button>
                                                            </div>
                                                        ) : <></>
                                                    }
                                                </div>
                                                {containers.length > 1 && index === 0 ? <Line state="col" min={sidebarW + 250} move={(x: number) => {
                                                    const el: any = document.getElementsByClassName('layout-container-content-container')[1]
                                                    el.style.width = containerW - x + 'px'
                                                }} moveEnd={() => {
                                                    const el: any = document.getElementsByClassName('layout-container-content-container')[1]
                                                    setContainerW(el.offsetWidth)
                                                }} /> : <></>}
                                            </Fragment>
                                        ))
                                    }
                                </div>
                            )
                        }

                        {/* 右边侧边栏 */}
                        <div className='layout-container-rightsidebar'>
                            <RightSider modal={modal} examType={examType} examDetai={examDetail} isPreview={isPreview} courseType={courseType} courseCode={couseCode} params={
                                {
                                    repo,
                                    user,
                                    path: section?.path,
                                    sign: ''
                                }
                            } editMode={section?.editMode} changePath={editPath} />
                        </div>
                    </div>

                    <div className='layout-footer'>

                    </div>

                    <ScoreModal
                        show={scoreShow}
                        params={{
                            chapter: section?.path,
                            number: activeId,
                            repo: repo,
                            userId
                        }}
                        passMark={activitiesConfig?.passMask}
                        next={nextSection?.path}
                        onTryAgain={() => {
                            if (section?.type === 'video') {
                                // 视频：重新播放
                                videoPlayerRef?.current?.reset()
                            } else {
                                // 文本：清空作答
                                textMarkdowmRef?.current?.conent()
                            }
                            setXtermAction({
                                type: 'exec',
                                value: 'clear'
                            })
                            setFinish(false)
                            setScoreShow(false)
                        }}
                        onClose={() => {
                            setFinish(false)
                            setScoreShow(false)
                        }} onRightClick={() => {
                            if (nextSection?.path) {
                                onNextSection()
                            } else {
                                onBackHome()
                            }
                            setFinish(false)
                            setScoreShow(false)
                        }}
                    />

                    {
                        modal === '1' ? <TemplateModal open={info && info?.status !== 1 ? true : false} onOk={onStartup} /> : <></>
                    }

                    {
                        cloneSuccess && <Loading data={importBtn ? '文档导入中，大概还需要一会儿~' : 'Git项目文件上传中…'} btnShow={importBtn}></Loading>
                    }
                    <Exam />
                    {enterBeforeLoading && <EnterWorkspaceLoading />}
                    {/* <ContactUs /> */}
                </div >
            </SharedDataContext.Provider>
        </ManaComponents.Application>
    )
}

export default Layout
