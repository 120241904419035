import pako from 'pako'
import { textToBase64 } from '.'
import { FilterXSS } from 'xss'

/**
 * 使用pako对数据进行压缩并转为base64
 * @param data 
 * @returns  null 转换失败   string 转化后的数据
 */
export function compress_pako(data: any): string | null {
    try {
        const strData = typeof data !== 'string' ? JSON.stringify(data) : data
        const compressedData: Uint8Array = pako.deflate(strData)
        const str = Buffer.from(compressedData).toString('base64')
        return str
    } catch (error) {
        return null
    }
}

/**
 * 将数据转为base64
 * @param data 
 * @returns null 转换失败   string 转化后的数据
 */
export function dataTobase64(data: any): string | null {
    try {
        const strData = typeof data !== 'string' ? JSON.stringify(data) : data
        const str = textToBase64(strData)
        return str
    } catch (error) {
        return null
    }
}

let customXSS: any
// 处理富文本字符串，防止xss注入
export function customFilterXSS(str: string) {
    if (!customXSS) {
        customXSS = new FilterXSS({})
    }
    return customXSS.process(str)
}