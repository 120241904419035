import { getConfigOne } from "@/api/modules/community";
import { useEffect } from "react";

let aiEnabled: any = undefined

export const useAIEnabled = () => {

    const getAiEnabled = (platform: 'quiz' | 'run', scope: string): boolean => {
        if (aiEnabled && aiEnabled[platform]) {
            const list = aiEnabled[platform].scope ?? []
            if (list.includes(scope)) {
                return true
            }
        }
        return false
    }

    useEffect(() => {
        if (!aiEnabled) {
            // 请求全局相关工具是否开启ai
            getConfigOne({ code: 'AI_ENABLE', type: 'CODE_TOOL_CONFIG' }).then((res) => {
                aiEnabled = res?.config ?? {}
            }).catch(() => { })
        }
    }, []);

    return [getAiEnabled]
}