import { FC, useEffect, useState } from "react";
import "./App.css";
import Layout from "./components/Layout";
import { getQueryStringValue } from "./utils/index";
import EnterWorkspaceLoading from "./library/EnterWorkSpaceLoading";
import postRobot from 'post-robot'

const App: FC = () => {
  // 加载状态
  const [loading, setLoading] = useState(false);
  // 授权状态
  const [hasAuth, setAuth] = useState(false);

  const repo = getQueryStringValue("repo");
  const userId = getQueryStringValue("userId");
  const sign = getQueryStringValue("sign");
  const partner = getQueryStringValue("partner");
  const token = getQueryStringValue("token");
  const origin = getQueryStringValue("origin"); //来源host

  // 授权并解锁
  const handleAuthAndUnlock = (e: any) => {
    const data = e?.data
    const type = data?.type
    if (type === 'authAndUnlock') {
      const _data = data?.data
      if (repo === _data?.repo) {
        setAuth(true)
        setLoading(false)
      }
    }
  };

  // 处理登录token发送
  const ListenToken = () => {
    postRobot.on('getToken', async (event: any) => {
      return { token }
    })
  }

  // 初始化
  const handleInit = () => {
    if (sign) {
      window.sessionStorage.setItem("sign", sign);
    }
    if (partner) {
      window.sessionStorage.setItem("partner", partner);
    }
    if (token) {
      window.sessionStorage.setItem("token", token);
    }
    if (token) {
      // 无需授权
      setAuth(true);
      return;
    } else {
      setLoading(true);
      // 先向机构发送认证消息
      const target = window?.opener || window?.parent;
      target?.postMessage(
        {
          type: "workspaceOnLoad",
          data: {
            partner,
            repo,
            userId,
            sign,
            origin,
          },
        },
        origin || "*"
      );
    }
    // 监听消息
    window.addEventListener("message", handleAuthAndUnlock);
  };

  useEffect(() => {
    ListenToken()
    handleInit();
    return () => {
      window.removeEventListener("message", handleAuthAndUnlock);
    };
  }, []);

  return (
    <div className="App">
      {hasAuth ? <Layout /> : loading ? <EnterWorkspaceLoading /> : <></>}
    </div>
  );
};

export default App;
