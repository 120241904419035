import axios, { AxiosRequestConfig } from 'axios'
import { message } from 'antd';
import Config from '../config'
import Cookies from 'js-cookie';
import { compress_pako, dataTobase64 } from '@/utils/common';
import { nanoid } from 'nanoid';

// 设置请求头和请求路径
axios.defaults.baseURL = Config.baseURL
axios.defaults.timeout = Config.requestTimeOut
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

// 请求拦截器
axios.interceptors.request.use(
  config => {
    // const uniqueKey = window.sessionStorage.getItem('uniqueKey')
    const sign = window.sessionStorage.getItem('sign')
    const partner = window.sessionStorage.getItem('partner')
    const token =window.sessionStorage.getItem('token')
    if (sign) {
      config.headers.sign = encodeURIComponent(sign)
    }
    if (partner) {
      config.headers.partner = encodeURIComponent(partner)
    }
    if (token) {
      config.headers.token = encodeURIComponent(token)
    }
    // 请求头携带需要对请求体编码，统一编码
    if (config.headers.EncodeScheme && (config.headers.EncodeScheme in EncodeTypeEnum)) {
      // 目前只处理post请求
      if (config.method === 'post') {
        const _data = {
          requestId: nanoid(),
          encodeData: encryptData(config.data, config.headers.EncodeScheme),
          signKey: undefined
        }
        config.data = _data
      }
    }
    return config
  },
  error => {
    setTimeout(() => {
      message.error(error || '请求错误')
    }, 0);
    return Promise.reject(error)
  }
)

// 响应拦截
axios.interceptors.response.use(
  res => {
    const { statusCode } = res?.data
    if (statusCode === 201) {
      return Promise.reject(res?.data)
    }
    return res.data
  },
  error => {
    const { response = {} } = error
    const { status } = response

    if (status === 404) {
      return Promise.reject(error)
    }
    if (status === 401) {
      console.log('无权限');
    }

    // if (status === 500) {
    //   const { data } = response
    //   message.error(data?.message)
    // }

    if (response) {
      const { data } = response
      return Promise.reject(data)
    } else {
      setTimeout(() => {
        message.error('请求网络错误')
      }, 0);
      return Promise.reject(error)
    }
  }
)

interface Http {
  get<T>(url: string, params?: T, config?: AxiosRequestConfig): Promise<any>
  post<T>(url: string, data?: T, config?: AxiosRequestConfig): Promise<any>
  put<T>(url: string, data?: T, config?: AxiosRequestConfig): Promise<any>
  delete<T>(url: string, params?: T, config?: AxiosRequestConfig): Promise<any>
}

const http: Http = {
  get(url, params, config) {
    return axios.get(url, { params, ...config })
  },
  post(url, data, config) {
    let d
    if (config?.headers?.['Content-Type'] === 'multipart/form-data') {
      d = data
    } else {
      d = JSON.stringify(data)
    }
    return axios.post(url, d, config)
  },
  put(url, data, config) {
    return axios.put(url, JSON.stringify(data), config)
  },
  delete(url, params, config) {
    return axios.delete(url, { params, ...config })
  }
}
export default http



// 当前支持的类型  默认的类型
export enum EncodeTypeEnum {
  'BASE64' = 'BASE64',
  'PAKO' = 'PAKO'
}
export type EncodeType = keyof typeof EncodeTypeEnum
export const defaultEncodeType: EncodeType = EncodeTypeEnum.BASE64
export const encodeHeaders = { headers: { EncodeScheme: defaultEncodeType } }

// 对参数数据进行加密
export function encryptData(data: any, encodeType: EncodeType = defaultEncodeType) {
  if (encodeType === EncodeTypeEnum.BASE64) {
    return dataTobase64(data)
  } else if (encodeType === EncodeTypeEnum.PAKO) {
    return compress_pako(data)
  }
}
