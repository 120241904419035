import React, { useRef } from 'react'
import './index.less'
import postRobot from 'post-robot'
import config from '@/config'
import { useAIEnabled } from '@/hooks/useAIEnables'

type CodeRunnerProps = {
    data: any
    changeData: (data: any) => Promise<any>
}

const CodeRunner: React.FC<CodeRunnerProps> = (props) => {

    const { data, changeData } = props

    const iframeDom = useRef<HTMLIFrameElement>(null)

    const [getAiEnabled] = useAIEnabled()

    const onLoad = async () => {    
        try {
            const win = iframeDom.current?.contentWindow!
            const {
                topic: title = '',
                title: content = '',
                codeLanguage: lang = '',
                code = ''
            } = data
            let codes = [code]
            if (lang === 'html') {
                try {
                    const all = JSON.parse(code)
                    codes = [all.html, all.css, all.js]
                } catch (error) { }
            }
            let response = await postRobot.send(win, 'send', {
                title, content, lang, code: codes, aiEnabled: getAiEnabled('run', 'editorActivity'), run: false, dark: true,
            })
            
            let formData = { ...data}
            // 代码块编辑回调
            postRobot.on("change", { window: iframeDom.current?.contentWindow }, async function (event: any) {
                if (lang === 'html') {
                    try {
                        const code = {
                            html: event.data?.[0] ?? '',
                            css: event.data?.[1] ?? '',
                            js: event.data?.[2] ?? '',
                        }
                        formData.code = JSON.stringify(code)
                    } catch (error) {
                        formData.code = event.data?.[0] ?? '' 
                    }
                } else {
                    formData.code = event.data?.[0] ?? '' 
                }
                changeData(formData)
            })
            // // 刷新回调
            // postRobot.on("refresh", { window: iframeDom.current?.contentWindow }, async function (event: any) {
            //     console.log('🚀', event)
            // })
        } catch (error) {
            
        }
    }

    return (
        <div className='CodeRunner'>
            <iframe
                ref={iframeDom}
                allowFullScreen={true}
                allow="clipboard-write;clipboard-read"
                // loading="lazy"
                src={`${config.codePlayerURL}/embedded`}
                frameBorder="0"
                title='CodeRunner'
                className='CodeRunner-iframe'
                onLoad={onLoad}
            ></iframe>
        </div>
    )
}

export default CodeRunner