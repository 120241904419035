import React, { useEffect, useState } from 'react'
import './index.less'
import { Form, FormInstance } from 'antd'
import MiniCodeEditor from '../MiniCodeEditor'
import classNames from 'classnames'

type CodeProps = {
    form?: FormInstance
    formData: any
}

const Code: React.FC<CodeProps> = (props) => {

    const { form, formData } = props

    // html三种语言 和相应的代码
    const [htmlLang, setHtmlLang] = useState('')
    const [threeCode, setThreeCode] = useState<any>({})
    // 当前的代码  当前的语言
    const [code, setCode] = useState('')
    const [lang, setLang] = useState('')

    // 特殊处理，因为html可以同时存在三种语言，所以这边需要将三种语言合并成json字符串进行处理和使用
    useEffect(() => {
        if (formData.codeLanguage === 'html') {
            try {
                const allCode = JSON.parse(formData?.code)
                setThreeCode(allCode)
                setCode(allCode?.html ?? '')
                saveCodeToForm(formData?.code)
            } catch (error) {
                const data = { html: formData?.code || '', css: '', js: '' }
                setThreeCode(data)
                setCode(formData?.code || '')
                saveCodeToForm(JSON.stringify(data))
            }
            setHtmlLang('html')
            setLang('html')
        } else {
            saveCodeToForm(formData?.code || '')
            setLang(formData.codeLanguage)
            setCode(formData?.code || '')
        }
    }, [formData])

    const onChange = (value: string) => {
        if (formData.codeLanguage === 'html') {
            const data: any = {...threeCode}
            data[htmlLang] = value
            setThreeCode(data)
            try {
                setCode(value)
                saveCodeToForm(JSON.stringify(data))
            } catch (error) {}
        } else {
            saveCodeToForm(value)
        }
    }

    // 将语言保存到表单中
    const saveCodeToForm = (code: string) => {
        form?.setFieldValue('code', code)
    }

    return (
        <div className='content1'>
            <Form.Item
                label='初始代码'
                name='code'
                // required
                rules={[
                    // { required: true, message: '请输入示例代码', validateTrigger: "onSubmit", whitespace: true },
                ]}
                validateFirst
            >
                <div className='code-container' style={{ width: '100%', height: 'calc(100vh - 254px)', background: 'black' }}>
                    {
                        formData.codeLanguage === 'html' && <div className='code-container-nav'>
                            {
                                [
                                    {
                                        label: 'Html',
                                        value: 'html',
                                    },
                                    {
                                        label: 'CSS',
                                        value: 'css',
                                    },
                                    {
                                        label: 'JavaScript',
                                        value: 'js',
                                    },
                                ].map((item: any) => {
                                    return <span key={item.label} onClick={() => { setHtmlLang(item.value); setLang(item.value); setCode(threeCode[item.value]) }} className={classNames({ 'light': htmlLang === item.value })}>{item.label}</span>
                                })
                            }
                        </div>
                    }
                    <div className='code-container-editor'>
                        <MiniCodeEditor value={code} language={lang} onChange={onChange} />
                    </div>
                </div>
            </Form.Item>
        </div>
    )
}

export default Code