import React, { memo, useEffect } from 'react'
import './index.less'
import { Form, FormInstance, Input, InputNumber, Radio, Select, Space } from 'antd'
import SvgIcon from '@/components/SvgIcon'
import ListItem from './ListItem'
import { CCMJudgeMode, CCMVerifyMode } from '.'

type VerifyProps = {
    form?: FormInstance
    formData: any
}

const Verify: React.FC<VerifyProps> = memo((props) => {

    const { form, formData } = props

    const paramNum = Form.useWatch('paramNum', form)

    useEffect(() => {
        // 校验模式  输入输出校验 主观校验
        if (formData?.verifyMode === CCMVerifyMode.INPUT_OUTPUT) {
            form?.setFieldsValue({
                paramNum: formData?.paramNum ? formData.paramNum : 1,
                example: formData?.example?.length ? formData.example : [{ input: [''], output: '' }],
                testExample: formData?.testExample?.length ? formData.testExample : [{ input: [''], output: '' }],
                judgeMode: formData?.judgeMode ?? CCMJudgeMode.AUTOMATIC
            })
        } else {
            form?.setFieldsValue({
                judgeMode: formData?.judgeMode ?? CCMJudgeMode.MANUAL
            })
        }
    }, [formData])

    // 输入输出校验需要监听个数
    useEffect(() => {
        if (formData?.verifyMode !== CCMVerifyMode.INPUT_OUTPUT) return
        if (!paramNum) return
        const { example, testExample } = form?.getFieldsValue(['example', 'testExample'])
        if (paramNum === example[0].input.length) {
            return
        }
        form?.setFieldsValue({
            example: example.map((item: any) => ({ ...item, input: newArr(item.input, paramNum) })),
            testExample: testExample.map((item: any) => ({ ...item, input: newArr(item.input, paramNum) }))
        })

        function newArr(arr: string[], length: number) {
            let result: string[] = []
            if (arr.length < length) {
                result = arr.concat(Array.from({ length: length - arr.length }, () => ''))
                return result
            }
            return arr.slice(0, length)
        }
    }, [paramNum, formData?.verifyMode])

    return (
        <div className='content2'>
            {
                (formData?.verifyMode === CCMVerifyMode.INPUT_OUTPUT) ? <>
                    <Form.Item
                        label='输入参数'
                        name='paramNum'
                        rules={[
                            { required: true, message: '请输入参数个数', validateTrigger: "onSubmit", type: 'number' }
                        ]}
                        validateFirst
                    >
                        <InputNumber
                            min={1}
                            max={10}
                            addonAfter={<span style={{ color: '#333333' }}>个</span>}
                            style={{ width: '140px' }}
                        />
                    </Form.Item>

                    <Form.Item
                        label='测试用例'
                        required
                        style={{ marginBottom: '10px' }}
                    >
                        <div className='list-tip'>
                            <TipSvg />
                            <span>考生可见，帮助考生理解输入输出</span>
                        </div>
                        <Form.List name="example">
                            {
                                (fields, { add, remove }, { errors }) => {
                                    return <>
                                        {
                                            fields.map((field) => {
                                                return <ListItem key={field.key} field={field} remove={remove} length={fields.length} />
                                            })
                                        }
                                        <div className='list-add' data-disabled={fields?.length >= 10} onClick={() => {
                                            if (fields?.length < 10) {
                                                add({ input: Array.from({ length: paramNum ?? 1 }, () => ''), output: '' })
                                            }
                                        }}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.33398 7.33301V4.66634H8.66732V7.33301H11.334V8.66634H8.66732V11.333H7.33398V8.66634H4.66732V7.33301H7.33398ZM8.00065 14.6663C4.31875 14.6663 1.33398 11.6815 1.33398 7.99967C1.33398 4.31777 4.31875 1.33301 8.00065 1.33301C11.6825 1.33301 14.6673 4.31777 14.6673 7.99967C14.6673 11.6815 11.6825 14.6663 8.00065 14.6663ZM8.00065 13.333C10.9462 13.333 13.334 10.9452 13.334 7.99967C13.334 5.05415 10.9462 2.66634 8.00065 2.66634C5.05513 2.66634 2.66732 5.05415 2.66732 7.99967C2.66732 10.9452 5.05513 13.333 8.00065 13.333Z" />
                                            </svg>
                                            <span>新增样例</span>
                                        </div>
                                    </>
                                }
                            }
                        </Form.List>
                    </Form.Item>

                    <Form.Item
                        label='校验用例'
                        required
                        style={{ marginBottom: '10px' }}
                    >
                        <div className='list-tip'>
                            <TipSvg />
                            <span>考生不可见，用于测试考生代码正确性</span>
                        </div>
                        <Form.List name="testExample">
                            {
                                (fields, { add, remove }, { errors }) => {
                                    return <>
                                        {
                                            fields.map((field) => {
                                                return <ListItem key={field.key} field={field} remove={remove} length={fields.length} />
                                            })
                                        }
                                        <div className='list-add' data-disabled={fields?.length >= 10} onClick={() => {
                                            if (fields?.length < 10) {
                                                add({ input: Array.from({ length: paramNum ?? 1 }, () => ''), output: '' })
                                            }
                                        }}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.33398 7.33301V4.66634H8.66732V7.33301H11.334V8.66634H8.66732V11.333H7.33398V8.66634H4.66732V7.33301H7.33398ZM8.00065 14.6663C4.31875 14.6663 1.33398 11.6815 1.33398 7.99967C1.33398 4.31777 4.31875 1.33301 8.00065 1.33301C11.6825 1.33301 14.6673 4.31777 14.6673 7.99967C14.6673 11.6815 11.6825 14.6663 8.00065 14.6663ZM8.00065 13.333C10.9462 13.333 13.334 10.9452 13.334 7.99967C13.334 5.05415 10.9462 2.66634 8.00065 2.66634C5.05513 2.66634 2.66732 5.05415 2.66732 7.99967C2.66732 10.9452 5.05513 13.333 8.00065 13.333Z" />
                                            </svg>
                                            <span>新增测试用例</span>
                                        </div>
                                    </>
                                }
                            }
                        </Form.List>
                    </Form.Item>

                    <Form.Item
                        label='判分配置'
                        name='judgeMode'
                        rules={[
                            { required: true, message: '请选择校验方式', validateTrigger: "onSubmit", whitespace: true },
                        ]}
                        validateFirst
                    >
                        <Radio.Group style={{ paddingTop: '5px' }}>
                            <Space direction="vertical">
                                <Radio value={CCMJudgeMode.AUTOMATIC} >根据校验用例进行全匹配校验</Radio>
                                <Radio value={CCMJudgeMode.AI_AGENT} disabled >根据校验用例进行AI校验</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </> : <>
                    <Form.Item
                        label='判分配置'
                        name='judgeMode'
                        rules={[
                            { required: true, message: '请选择校验方式', validateTrigger: "onSubmit", whitespace: true },
                        ]}
                        validateFirst
                    >
                        <Radio.Group style={{ paddingTop: '5px' }}>
                            <Space direction="vertical">
                                <Radio value={CCMJudgeMode.MANUAL} >手动校验</Radio>
                                <Radio disabled value={CCMJudgeMode.AI_AGENT} >使用AI进行校验</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </>
            }
        </div>
    )
})

const TipSvg = () => {
    return <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 1.00488C10.3109 1.00488 12.9954 3.68931 12.9954 7.00024C12.9954 10.3112 10.3109 12.9956 7 12.9956C3.68906 12.9956 1.00464 10.3112 1.00464 7.00024C1.00464 3.68931 3.68906 1.00488 7 1.00488ZM7 2.00411C4.24113 2.00411 2.00387 4.24138 2.00387 7.00024C2.00387 9.75911 4.24113 11.9964 7 11.9964C9.75887 11.9964 11.9961 9.75911 11.9961 7.00024C11.9961 4.24138 9.75887 2.00411 7 2.00411ZM7.10692 8.71342C7.30568 8.71342 7.49629 8.79238 7.63684 8.93292C7.77738 9.07346 7.85634 9.26408 7.85634 9.46284C7.85634 9.6616 7.77738 9.85221 7.63684 9.99276C7.49629 10.1333 7.30568 10.2123 7.10692 10.2123C6.90816 10.2123 6.71754 10.1333 6.577 9.99276C6.43645 9.85221 6.3575 9.6616 6.3575 9.46284C6.3575 9.26408 6.43645 9.07346 6.577 8.93292C6.71754 8.79238 6.90816 8.71342 7.10692 8.71342ZM7.07144 4.00256C7.20395 4.00256 7.33103 4.0552 7.42473 4.1489C7.51842 4.24259 7.57106 4.36967 7.57106 4.50218V7.35697C7.57106 7.48947 7.51842 7.61655 7.42473 7.71025C7.33103 7.80394 7.20395 7.85658 7.07144 7.85658C6.93894 7.85658 6.81186 7.80394 6.71816 7.71025C6.62447 7.61655 6.57183 7.48947 6.57183 7.35697V4.50218C6.57183 4.36967 6.62447 4.24259 6.71816 4.1489C6.81186 4.0552 6.93894 4.00256 7.07144 4.00256Z" fill="#999999" />
    </svg>
}

export default Verify