import React, { useEffect, useState } from 'react'
import './index.less'
import { Button, Form, Modal, Space } from 'antd'
import ModalClose from '../ModalClose'
import Step from '../Step'
import Preview from './Preview'
import Code from './Code'
import Content from './Content'
import { handleTimeSecond } from '@/utils/time'
import Verify from './Verify'
import { queryAcmLanguageCustomList, queryActivityKnowledge } from '@/api/modules/community'
import Debug from './Debug'

type CodeTestProps = {
    open: boolean
    onClose?: () => void
    value?: any
    time?: boolean           // 是否需要显示时间控件  默认不需要
    initTime?: string        // 如果需要可以提供一个初始值
    veriryTime?: (speed: number, id?: string) => any// 如果需要时间空间还可以提供一个校验时间的控件
    finish?: (data: any) => Promise<any>
    chapterId: string             // 小节id
    courseCode: string            // 课程code
}

const defaultSteps = [
    {
        type: 'unfinish',
        title: '内容设置'
    },
    {
        type: 'unfinish',
        title: '初始代码设置'
    },
    {
        type: 'unfinish',
        title: '校验设置'
    },
    {
        type: 'unfinish',
        title: '调试验证'
    },
    {
        type: 'unfinish',
        title: '预览'
    },
]

/**
 * 编码测验题校验模式 
 *      NONE(0, "无校验"), 
 *      INPUT_OUTPUT(1, "输入输出校验"), 
 *      SUBJECTIVE(2, "主观校验"),
 */
export enum CCMVerifyMode {
    NONE = 'NONE',
    INPUT_OUTPUT = 'INPUT_OUTPUT',
    SUBJECTIVE = 'SUBJECTIVE'
}

/**
 *  判分配置
 */
export enum CCMJudgeMode {
    MANUAL = 'MANUAL',        // 手动
    AUTOMATIC = 'AUTOMATIC',  // 自动  使用判分用例
    AI_AGENT = 'AI_AGENT',    // ai模式
}

// 编码测验
const CodeTest: React.FC<CodeTestProps> = (props) => {

    const { open, onClose, value, time = false, initTime = '00:00:00', veriryTime, finish, chapterId, courseCode } = props

    const [form] = Form.useForm()
    const [steps, setSteps] = useState(defaultSteps.map(item => ({ ...item })))   // 步骤
    const [current, setCurrent] = useState(0)          // 当前步骤
    const [loading, setLoading] = useState(false)      // 保存加载
    const [formData, setFormData] = useState({})       // 表单数据
    const [languageList, setLanguageList] = useState<any>([])  // 编程语言列表

    // 保存
    const save = async () => {
        const data: any = { ...formData }
        data.knowledgeIds = (formData as any)?.knowledgeIds?.map((item: any) => item.knowledgeId)
        setLoading(true)
        await finish?.(data)
        setLoading(false)
        onClose?.()
    }

    // 切换当前步骤
    const changeSetp = async (pre: number, next: number) => {
        if (pre === next) return

        // 向下需要判断是否可达，比如不能直接从第一步直接跳到第三部
        // 向上需要当前的步骤是否可以通过
        if (next > pre || steps[pre].type === 'finish') {
            // 先校验当前的表单是否可以通过  第一步和第二步才有表单
            const newSteps = [...steps]
            if ([0, 1, 2].includes(pre)) {
                const data = await form?.validateFields().catch(() => { })
                if (!data) return
                // 针对时间的格式进行处理
                if (data?.time) {
                    data.speed = handleTimeSecond(data.time)
                    data.time = data.time.format('HH:mm:ss')
                }
                // 针对校验
                if (data?.verifyMode && data.verifyMode !== CCMVerifyMode.INPUT_OUTPUT) {
                    setFormData({ ...formData, ...data, paramNum: undefined, example: undefined, testExample: undefined })
                } else {
                    setFormData({ ...formData, ...data })
                }
                newSteps[pre].type = 'finish'
                setSteps(newSteps)
            } else {
                newSteps[pre].type = 'finish'
                setSteps(newSteps)
            }
            for (let i = pre; i < next; i++) {
                const step = newSteps[i]
                if (step.type === 'unfinish') {
                    return
                }
            }
        }
        setCurrent(next)
    }

    useEffect(() => {
        if (open) {
            setLoading(false)
            handleInitFormData(value)
            setSteps(value ? defaultSteps.map((item) => ({ ...item, type: 'finish' })) : defaultSteps.map(item => ({ ...item })))
            setCurrent(0)
            fetchList()
        } else {
            setFormData({})
        }
    }, [open, value])

    const handleInitFormData = async (value: any) => {
        if (value) {
            const list = await fetchLabels('UPDATE', value.knowledgeIds, value.id)
            setFormData({ ...value, knowledgeIds: list?.knowledgeList ?? [] })
        } else {
            const list = await fetchLabels()
            setFormData({ knowledgeIds: list?.knowledgeList ?? [] })
        }
    }

    const fetchLabels = async (operate: 'CREATE' | 'UPDATE' = 'CREATE', knowledgeIds?: string[], resourceId?: string) => {
        const data = await queryActivityKnowledge({
            chapterId,
            courseCode,
            operate,
            knowledgeIds,
            resourceId
        }).catch(() => { })
        return data
    }

    // 获取编程语言列表
    const fetchList = async () => {
        const res = await queryAcmLanguageCustomList({ category: 'ccm', questionType: 0 }).catch(() => { })
        if (!res) return
        setLanguageList(res)
    }

    if (!open) return null

    return (
        <Modal
            open={open}
            wrapClassName='act-codetest-modal'
            onCancel={onClose}
            footer={false}
            closable={false}
            centered={true}
            width={896}
            maskClosable={false}
            destroyOnClose={true}
        >
            <ModalClose className='close' onClose={onClose} />
            <p className='title-top'>{value ? '编辑' : '添加'}编码测验</p>
            <Step onClick={changeSetp} steps={steps as any} current={current} style={{ marginBottom: '20px', padding: '0 50px' }} />

            <div className='content'>
                {
                    current === 4 ? <Preview formData={formData} /> :
                        <Form
                            name="act-codetest-modal-form"
                            colon={false}
                            autoComplete="off"
                            form={form}
                        >
                            {
                                current === 0 ? <Content chapterId={chapterId} courseCode={courseCode} disableTime={!(value && Object.keys(value)?.length)} formData={formData} form={form} time={time} initTime={initTime} veriryTime={veriryTime} languageList={languageList} setSteps={setSteps} steps={steps} setFormData={setFormData} /> :
                                    current === 1 ? <Code form={form} formData={formData} /> :
                                        current === 2 ? <Verify form={form} formData={formData} /> :
                                            current === 3 ? <Debug form={form} formData={formData} changeData={setFormData} /> :
                                                <></>
                            }
                        </Form>
                }
            </div>

            <Space align="center" size={30} style={{ display: 'flex', justifyContent: 'center', height: '32px', marginTop: '40px' }}>
                <Button style={{ minWidth: '88px' }} onClick={() => { current === 0 ? onClose?.() : changeSetp(current, current - 1) }}>{current === 0 ? '取消' : '上一步'}</Button>
                <Button style={{ minWidth: '88px' }} loading={loading} type="primary" onClick={() => { current === steps.length - 1 ? save() : changeSetp(current, current + 1) }}>{current === steps.length - 1 ? '保存' : '下一步'}</Button>
            </Space>
        </Modal>
    )
}

export default CodeTest