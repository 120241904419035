import React, { useRef } from 'react'
import './index.less'
import postRobot from 'post-robot'
import config from '@/config'
import { useAIEnabled } from '@/hooks/useAIEnables'

type CcmQuizItemProps = {
    data: any
    changeData: (data: any) => Promise<any>
}

const CcmQuizItem: React.FC<CcmQuizItemProps> = (props) => {

    const { data, changeData } = props

    const iframeDom = useRef<HTMLIFrameElement>(null)

    const [getAiEnabled] = useAIEnabled()


    const onLoad = async () => {
        try {
            const win = iframeDom.current?.contentWindow!
            const {
                topic: title = '',
                title: content = '',
                codeLanguage: lang = '',
                code = '',
                example: cases = [],
                testExample: submitCases = [],
            } = data
            let response = await postRobot.send(win, 'send', {
                title, content, lang, initCode: code, code, cases, submitCases, aiEnabled: getAiEnabled('quiz', 'editorActivity'),
            })

            let formData = { ...data }
            // // 代码块编辑回调
            // postRobot.on("change", { window: iframeDom.current?.contentWindow }, async function (event: any) {
            //     formData.code = event.data ?? ''
            //     changeData(formData)
            // })
            // 其他字段编辑回调
            postRobot.on("saveQuestion", { window: iframeDom.current?.contentWindow }, async function (event: any) {
                if (event.data.title != undefined) {
                    const { title, content } = event.data
                    if (title?.length < 3 || title?.length > 50) {
                        return { error: '请输入3-50字的标题' }
                    }
                    if (content) {
                        const dom = (new DOMParser()).parseFromString(content, 'text/html')
                        if (!dom.getElementsByTagName('img')?.length && !dom.body.innerText) {
                            return { error: '请输入题干内容' }
                        }
                    } else {
                        return { error: '请输入题干内容' }
                    }
                    formData.topic = title
                    formData.title = content
                } else if (event.data?.cases != undefined) {
                    const { cases, submitCases } = event.data
                    if (!cases?.length) {
                        return { error: '请输入测试用例' }
                    } else if (cases?.length > 10) {
                        return { error: '测试用例至多设置10个' }
                    } else {
                        for (let i = 0; i < cases.length; i++) {
                            const item = cases[i]
                            if (!item.output) {
                                return { error: '测试用例输出不可为空' }
                            }
                            if (!item.input.every((str: string) => !!str)) {
                                return { error: '测试用例输入不可为空' }
                            }
                        }
                    }

                    if (!submitCases?.length) {
                        return { error: '请输入判分用例' }
                    } else if (submitCases?.length > 10) {
                        return { error: '判分用例至多设置10个' }
                    } else {
                        for (let i = 0; i < submitCases.length; i++) {
                            const item = submitCases[i]
                            if (!item.output) {
                                return { error: '判分用例输出不可为空' }
                            }
                            if (!item.input.every((str: string) => !!str)) {
                                return { error: '判分用例输入不可为空' }
                            }
                        }
                    }
                    formData.example = cases
                    formData.testExample = submitCases
                    if (cases?.[0]?.input?.length) {
                        formData.paramNum = cases?.[0]?.input?.length
                    }
                } else if (event.data?.initCode != undefined) {
                    formData.code = event.data?.initCode ?? ''
                }
                changeData(formData)
            })
        } catch (error) {

        }
    }

    return (
        <div className='ccmquizitem'>
            <iframe
                ref={iframeDom}
                allowFullScreen={true}
                allow="clipboard-write;clipboard-read"
                // loading="lazy"
                src={`${config.codeTestURL}/questionEditor`}
                frameBorder="0"
                title='codeplayer-questionEditor'
                className='codeplayer-questionEditor-iframe'
                onLoad={onLoad}
            ></iframe>
        </div>
    )
}

export default CcmQuizItem