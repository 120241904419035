import React from 'react'
import './index.less'
import MiniRichEditor from '../MiniRichEditor'
import { Button } from 'antd'
import postRobot from 'post-robot'
import config from '@/config'
import { CCMVerifyMode } from '.'
import { useAIEnabled } from '@/hooks/useAIEnables'

type PreviewProps = {
    formData: any
}

const Preview: React.FC<PreviewProps> = (props) => {

    const { formData } = props

    const [getAiEnabled] = useAIEnabled()


    const onClick = () => {
        if (formData?.verifyMode === CCMVerifyMode.INPUT_OUTPUT) {
            const win = window.open(config.codeTestURL)
            postRobot.send(win, "send", {
                title: formData?.topic ?? '',
                content: formData?.title ?? '',
                initCode: formData.code,
                code: formData.code,
                lang: formData.codeLanguage,
                cases: formData.example,
                submitCases: formData.testExample,
                noSubmit: true,
                aiEnabled: getAiEnabled('quiz', 'editorActivity'),
            })
        } else {
            const win = window.open(`${config.codePlayerURL}/embedded`)
            const send = () => {
                let code = [formData.code]
                if (formData.codeLanguage === 'html') {
                    try {
                        const all = JSON.parse(formData.code)
                        code = [all.html ?? '', all.css ?? '', all.js ?? '']
                    } catch (error) { }
                }
                postRobot.send(win, 'send', {
                    title: formData?.topic ?? '',
                    content: formData?.title ?? '',
                    lang: formData.codeLanguage,
                    code,
                    aiEnabled: getAiEnabled('run', 'editorActivity'),
                    run: false,
                    dark: true,
                })
            }
            send()
            postRobot.on("refresh", { window: win }, async function (event: any) {
                setTimeout(() => {
                    if (!win?.closed) {
                        send()
                    }
                }, 500);
            })
        }
    }

    return (
        <div className='preview-container'>
            <div className='preview'>
                <div className='preview-title'>可视化预览</div>
                <div className='preview-content'>
                    <p className='preview-content-title'>编码测验</p>
                    <p className='preview-content-topic'>{formData?.topic}</p>
                    <MiniRichEditor height={'auto'} readOnly border={false} value={formData.title} />
                    <Button onClick={onClick} type='primary' style={{minWidth: '88px'}}>{formData?.buttonName}</Button>
                </div>
            </div>
        </div>
    )
}

export default Preview